import PageHeading from "../../components/PageHeading";
import ExtendedTable from "../../components/ExtendedTable";
import _ from 'lodash';
import {
    createNomencGroup, getAssignmentsRegionSearch,
    getNomenclatureGroups, getSingleNomencGroup, updateNomencGroup,
} from "../../adapters/api.adapter";
import {useHistory} from "react-router-dom";
import {AutoComplete, Button, Col, Form, Input, Radio, Row, Select} from "antd";
import {useState} from "react";
import {CheckOutlined, CloseOutlined, FileSearchOutlined, PlusOutlined} from "@ant-design/icons";
import GenericFormModal from "../../forms/genericFormModal";

const shortenedName = (s = "Dəmir-beton konstruksiyaların armaturlanması üçün prokat, dairəvi və dövri profilli, qaynar yayılmış və termomexaniki, termik möhkəmləndirilmiş, A-I sinifli, diametri 6 mm") => {
    if (s.length > 150) {
        return s.substring(0, 29) + "..." + s.substring(s.length - 30, s.length);
    }
    return s;
}

export default function Category() {
    const history = useHistory()
    const [selectedType, setSelectedType] = useState(1);
    const [isUpdated, setIsUpdated] = useState();

    const [editingRecordId, setEditingRecordId] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [ignoreRecord, setIgnoreRecord] = useState();
    const [itemSearch, setItemSearch] = useState([]);

    const [formState, setFormState] = useState(0);


    const [editingItems, setEditingItems] = useState([])

    const fetchItems = _.debounce(async (t) => {
        const items = await getAssignmentsRegionSearch({
            is_global: 1,
            regions: [0],
            assignment_type: selectedType,
            name: t,
        })
        setItemSearch(items.data.map(t => ({
            label: `${t.field_code} / ${t.fhn_id} / ${shortenedName(t.product_name)}`,
            value: t.id,
            key: t.id
        })));
    }, 500, {trailing: true})


    return (
        <div>
            <PageHeading title={"Nomenklatura Qrupları"} path={"Panel/Tənzimləmələr/Nomeknlatura qrupları"}/>
            <Row span={24} style={{marginBottom: "10px"}} gutter={4}>
                <Col span={12}>
                    <Select defaultValue={selectedType} value={selectedType} onSelect={(k) => {
                        setSelectedType(k);
                        setIsUpdated(!isUpdated)
                    }}>
                        <Select.Option value={1}>Tikintidə istifadə edilən materiallar, məmulatlar,
                            konstruksiyalar,
                            mebel - inventar və avadanlıqlar üzrə məlumatlar</Select.Option>
                        <Select.Option value={2}> Tikinti maşın - mexanizmlərin və avtonəqliyyat
                            vasitələrinin istismar
                            xərcləri
                            barədə
                            məlumatlar</Select.Option>
                        <Select.Option value={3}>Tikintidə əməyin ödənilməsinə aid
                            məlumatlar</Select.Option>
                        <Select.Option value={4}>Quru, dəniz və hava nəqliyyatı növləri vasitəsilə yükün
                            daşınması
                            barədə məlumatlar</Select.Option>
                    </Select>
                </Col>
                <Col span={12} style={{textAlign: "right"}}>
                    <Button icon={<PlusOutlined/>} type={"primary"} onClick={() => {
                        setIsModalVisible(true);
                        setIgnoreRecord(true)
                    }}>
                        Əlavə et
                    </Button>
                </Col>
            </Row>
            <GenericFormModal
                ignoreRecord={ignoreRecord}
                width={1000}
                onSubmit={() => {
                    setIsUpdated(!isUpdated);
                    setFormState(0);
                    setItemSearch([]);
                }}
                recordId={editingRecordId}
                setIsModalVisible={setIsModalVisible}
                update={() => setIsUpdated(!isUpdated)}
                dataEntrance={(record) => {
                    setEditingItems(record.nomenclature_items)
                    record.nomenclature_items = record.nomenclature_items.map(t => t.value);
                    return record
                }}
                onClose={() => {
                    setEditingItems([]);
                    setItemSearch([]);
                    setFormState(0)
                }}
                mainFunctions={{get: getSingleNomencGroup, create: createNomencGroup, update: updateNomencGroup}}
                isModalVisible={isModalVisible}>
                <Form.Item
                    name={"name"}
                    label={"Qrup adı"}
                    rules={[
                        {
                            required: true,
                            message: "Qrup adı daxil edin!"
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name={"assignment_type"}
                    label={"Nomenklatura Növü"}
                    rules={[
                        {
                            required: true,
                            message: "Nomenklatura Növü"
                        }
                    ]}
                >
                    <Select disabled={formState > 0} onSelect={(t) => {
                        setSelectedType(t);
                        setItemSearch([]);
                    }}>
                        <Select.Option value={1}>Tikintidə istifadə edilən materiallar, məmulatlar,
                            konstruksiyalar,
                            mebel - inventar və avadanlıqlar üzrə məlumatlar</Select.Option>
                        <Select.Option value={2}> Tikinti maşın - mexanizmlərin və avtonəqliyyat
                            vasitələrinin istismar
                            xərcləri
                            barədə
                            məlumatlar</Select.Option>
                        <Select.Option value={3}>Tikintidə əməyin ödənilməsinə aid
                            məlumatlar</Select.Option>
                        <Select.Option value={4}>Quru, dəniz və hava nəqliyyatı növləri vasitəsilə yükün
                            daşınması
                            barədə məlumatlar</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name={"nomenclature_items"}
                    label={`Məhsullar ${editingRecordId && !ignoreRecord ? "(Əlavə etmək üçün axtarış edin)" : ""}`}
                    rules={[
                        {
                            required: true,
                            message: "Məhsullar daxil edin!"
                        }
                    ]}
                >
                    <Select
                        placeholder={"Ad və ya sahə kodu üzrə axtarış üzrə axtarış"}
                        mode={"multiple"}
                        allowClear
                        onSelect={() => setFormState(1)
                        }
                        onClear={() => setFormState(0)}
                        showSearch={true}
                        filterOption={false}
                        optionLabelProp={"label"}
                        onSearch={(e) => fetchItems(e)}
                        options={itemSearch.length !== 0 ? itemSearch : editingItems !== 0 ? [...editingItems, ...itemSearch] : []}/>
                </Form.Item>
                {
                    (() => {
                        if (editingRecordId && !ignoreRecord) {
                            return (
                                <Form.Item
                                    name={"is_active"}
                                    label={"Statusu"}
                                >
                                    <Radio.Group>
                                        <Radio value={0}>Deaktiv</Radio>
                                        <Radio value={1}>Aktiv</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            )
                        }
                    })()
                }
            </GenericFormModal>
            <ExtendedTable
                history={history}
                update={isUpdated}
                fetchFunction={getNomenclatureGroups(selectedType)}
                actions={{
                    edit: (id) => {
                        setEditingRecordId(id);
                        setIgnoreRecord(false);
                        setIsModalVisible(true);
                    }
                }}
            />
        </div>
    )
}