import {useHistory} from "react-router-dom";
import PageHeading from "../../components/PageHeading";
import ExtendedTable from "../../components/ExtendedTable";
import {
    deleteEmail,
    generateEmails,
    getEmailNotifications,
    sendPendingEmails
} from "../../adapters/api.adapter";
import {Button, message} from "antd";
import {useState} from "react";
import {DeleteOutlined} from "@ant-design/icons";

export default function EmailNotifications() {
    const history = useHistory();
    const [isEmailSendLoading, setIsEmailSendLoading] = useState(false);
    const [isUpdated, setIsUpdated] = useState();


    const sendPendingEmailsT = async () => {
        setIsEmailSendLoading(true);

        const result = window.confirm("Emaillər xəbərdarlıqlar göndərilsin?");
        if(!result) return setIsEmailSendLoading(false)

        try {
            await sendPendingEmails();
            setIsEmailSendLoading(false);
            setIsUpdated(!isUpdated);
            return message.success("Emaillər xəbərdarlıqlar göndərildi");
        } catch (e) {
            message.error("Sistemdə xəta baş verdi");
            setIsEmailSendLoading(false);
        }
    }

    const generateEmailsT = async () => {
        setIsEmailSendLoading(true);

        const result = window.confirm("Email xəbərdarlıqlar hazırlansın?");
        if(!result) return setIsEmailSendLoading(false)

        try {
            await generateEmails();
            setIsEmailSendLoading(false);
            setIsUpdated(!isUpdated);
            return message.success("Email xəbərdarlıqları hazırlandı");
        } catch (e) {
            message.error("Sistemdə xəta baş verdi");
            setIsEmailSendLoading(false);
        }
    }


    return (
        <div>
            <PageHeading title={"Email xəbərdarlıqlar"} path={"Panel/Hesabatlar/Email xəbərdarlıqlar"}>
                <Button loading={isEmailSendLoading} style={{width: "220px"}} onClick={sendPendingEmailsT} type={"primary"}>Email xəbərdarlıqları göndər</Button>
                <Button loading={isEmailSendLoading} onClick={generateEmailsT} type={"primary"} style={{marginLeft: "10px", width: "220px"}}>Email xəbərdarlıqları hazırla</Button>
            </PageHeading>
            <ExtendedTable
                history={history}
                update={isUpdated}
                actions={{
                    custom: [
                        {
                            icon: <DeleteOutlined style={{color: "orangered"}} />,
                            isShown: (text, record) => !record.status,
                            onClick: (id) => {
                                const prompt = window.confirm("Diqqət! Email xəbərdarlıq silinəcək!");

                                if(prompt) {
                                    deleteEmail(id).then(r => {
                                        message.success("Əməliyyat uğurla tamamlandı!");
                                        setIsUpdated(!isUpdated);
                                    });
                                }
                            }
                        }
                    ]
                }}
                fetchFunction={getEmailNotifications}
            />
        </div>
    )
}
