import {Switch, Route, useHistory} from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./pages/dashboard/App";
import Index from './pages/index/index';
import {useEffect, useState} from "react";
import "./App.css";
import {getAsanLoginToken} from "./config/asan_login";
import ErrorPage from "./pages/error/error";

function App() {
    const [user, setUser] = useState(localStorage.getItem('asan_login_token'));
    let history = useHistory();

    const handleLogin = e => {
        e.preventDefault();
        history.push("/dashboard")
    }


    const handleLogout = e => {
        e.preventDefault();
        localStorage.clear();
        history.push("/")
    }

    useEffect(() => {
        getAsanLoginToken().then(res => {
            localStorage.setItem("asan_login_token", res.data.token);
            setUser(res.data.token)
            if (history.location.pathname === "/") {
                history.push("/dashboard")
            }
        }).catch(e => {
        })

        return () => {
        }
    }, [])


    return (
        <>
            <Switch>
                <Route exact path="/" render={props => <Index {...props} user={user} handleLogin={handleLogin}/>}/>
                <Route path="/error" component={ErrorPage} />
                <ProtectedRoute path={"/dashboard"} user={user} handleLogout={handleLogout} component={Dashboard}/>
            </Switch>
        </>
    );
}

export default App;

