import {useHistory} from "react-router-dom";
import PageHeading from "../../components/PageHeading";
import {
    createNomenc,
    getAssignmentTypes,
    getItemsSettingsBasedOnModule,
    getMeasurementUnitsSelect,
    getNomencGroups,
    getSingleNomenc,
    updateSingleNomenc
} from "../../adapters/api.adapter";
import ExtendedTable from "../../components/ExtendedTable";
import {Button, Col, Row, Select, Form, Input} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import GenericFormModal from "../../forms/genericFormModal";
import qs from "qs";
import Text from "antd/es/typography/Text";

export default function Nomenclature() {
    const history = useHistory();
    const [isFirstMount, setIsFirstMount] = useState(true);
    const [isUpdated, setIsUpdated] = useState();
    const [ignoreRecord, setIgnoreRecord] = useState();
    const [editingRecordId, setEditingRecordId] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedType, setSelectedType] = useState(0);

    const [nomencGroups, setNomencGroups] = useState();
    const [assignmentTypes, setAssignmentTypes] = useState();
    const [measurementUnits, setMeasurementUnits] = useState();


    useEffect(() => {
        const url = new URL(window.location);
        const parsed = qs.parse(url.search.substring(1, url.search.length));
        setSelectedType(parseInt(parsed.module_type || 1))
    }, [])

    useEffect(() => {
        getAssignmentTypes().then(k => setAssignmentTypes(k));
        getNomencGroups().then(k => setNomencGroups(k));
        getMeasurementUnitsSelect().then(k => setMeasurementUnits(k));
    }, [])

    useEffect(() => {
        if (!isFirstMount) {
            const url = new URL(window.location);
            const parsed = qs.parse(url.search.substring(1, url.search.length));
            history.replace(decodeURIComponent(`${window.location.pathname}?${qs.stringify({
                ...parsed,
                module_type: selectedType
            }, {arrayFormat: 'brackets'})}`));
        }
        setIsFirstMount(false);
    }, [selectedType])

    return (
        <div>
            <PageHeading
                title={"Material və Xidmətlər"}
                path={"Panel/Tənzimləmələr/Material və Xidmətlər"}>
            </PageHeading>
            <Row span={24} style={{marginBottom: "10px"}} gutter={4}>
                <Col span={12}>
                    {
                        selectedType === 0 ? <></> :
                            <Row span={24}>
                                <Col span={6}>
                                    <Text style={{marginRight: "10px"}}>Material və xidmət növü seçin:</Text>
                                </Col>
                                <Col span={18}>
                                    <Select value={selectedType} onSelect={(k) => {
                                        setSelectedType(k);
                                        setIsUpdated(!isUpdated)
                                    }} style={{width: "100%"}}>
                                        <Select.Option value={1}>Tikintidə istifadə edilən materiallar, məmulatlar,
                                            konstruksiyalar,
                                            mebel - inventar və avadanlıqlar üzrə məlumatlar</Select.Option>
                                        <Select.Option value={2}> Tikinti maşın - mexanizmlərin və avtonəqliyyat
                                            vasitələrinin istismar
                                            xərcləri
                                            barədə
                                            məlumatlar</Select.Option>
                                        <Select.Option value={3}>Tikintidə əməyin ödənilməsinə aid
                                            məlumatlar</Select.Option>
                                        <Select.Option value={4}>Quru, dəniz və hava nəqliyyatı növləri vasitəsilə yükün
                                            daşınması
                                            barədə məlumatlar</Select.Option>
                                    </Select>
                                </Col>
                            </Row>
                    }
                </Col>
                <Col span={12} style={{textAlign: "right"}}>
                    <Button icon={<PlusOutlined/>} type={"primary"} onClick={() => {
                        setIsModalVisible(true);
                        setIgnoreRecord(true)
                    }}>
                        Əlavə et
                    </Button>
                </Col>
            </Row>
            <GenericFormModal
                ignoreRecord={ignoreRecord}
                recordId={editingRecordId}
                setIsModalVisible={setIsModalVisible}
                update={() => setIsUpdated(!isUpdated)}
                isModalVisible={isModalVisible}
                mainFunctions={{get: getSingleNomenc, update: updateSingleNomenc, create: createNomenc}}>
                <Form.Item
                    name={"id"}
                    label={"Nomenklatura kodu"}
                >
                    <Input disabled/>
                </Form.Item>
                <Form.Item
                    name={"name"}
                    label={"Nomenklatura adı"}
                    rules={[
                        {
                            required: true,
                            message: "Məhsul adı daxil edin!"
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name={"field_code"}
                    label={"Sahə kodu"}
                    rules={[
                        {
                            required: true,
                            message: "Sahə kodu daxil edin!"
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name={"measurement_unit"}
                    label={"Ölçü vahidi"}
                    rules={[
                        {
                            required: true,
                            message: "Ölçü vahidi seçin!"
                        }
                    ]}
                >
                    <Select>
                        {
                            measurementUnits ? measurementUnits?.map(k => <Select.Option value={k.value} key={k.value}>{k.title}</Select.Option>) : <></>
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name={"group_id"}
                    label={"Nomenklatura qrupu"}
                    rules={[
                        {
                            required: true,
                            message: "Nomenklatura qrupu seçin!"
                        }
                    ]}
                >
                    <Select>
                        {
                            nomencGroups ? nomencGroups?.map(k => <Select.Option value={k.value} key={k.value}>{k.label}</Select.Option>) : <></>
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    name={"module_type"}
                    label={"Avadanlıq və ya xidmət növü"}
                    rules={[
                        {
                            required: true,
                            message: "Avadanlıq və ya xidmət növü seçin!"
                        }
                    ]}
                >
                    <Select>
                        {
                            assignmentTypes ? assignmentTypes?.map(k => <Select.Option value={k.value} key={k.value}>{k.text}</Select.Option>) : <></>
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name={"fhn_id"}
                    label={"Eyniləşmə kodu"}
                >
                    <Input/>
                </Form.Item>

            </GenericFormModal>
            {
                (() => {
                    switch (selectedType) {
                        case 0:
                            return <></>
                        case 1:
                            return <ExtendedTable
                                history={history}
                                reset={isUpdated}
                                update={isUpdated}
                                actions={{
                                    edit: (id) => {
                                        setEditingRecordId(id);
                                        setIgnoreRecord(false);
                                        setIsModalVisible(true);
                                    }
                                }}
                                fetchFunction={getItemsSettingsBasedOnModule(selectedType)}
                            />
                        case 2:
                            return <ExtendedTable
                                history={history}
                                reset={isUpdated}
                                update={isUpdated}
                                actions={{
                                    edit: (id) => {
                                        setEditingRecordId(id);
                                        setIgnoreRecord(false);
                                        setIsModalVisible(true);
                                    }
                                }}
                                fetchFunction={getItemsSettingsBasedOnModule(selectedType)}
                            />
                        case 3:
                            return <ExtendedTable
                                history={history}
                                reset={isUpdated}
                                update={isUpdated}
                                actions={{
                                    edit: (id) => {
                                        setEditingRecordId(id);
                                        setIgnoreRecord(false);
                                        setIsModalVisible(true);
                                    }
                                }}
                                fetchFunction={getItemsSettingsBasedOnModule(selectedType)}
                            />
                        case 4:
                            return <ExtendedTable
                                history={history}
                                reset={isUpdated}
                                update={isUpdated}
                                actions={{
                                    edit: (id) => {
                                        setEditingRecordId(id);
                                        setIgnoreRecord(false);
                                        setIsModalVisible(true);
                                    }
                                }}
                                fetchFunction={getItemsSettingsBasedOnModule(selectedType)}
                            />
                    }
                })()
            }

        </div>
    )
}
