import UserContextProvider, {UserContext} from "../contexts/user.context";
import {useContext, useEffect} from "react";
import {Button, Dropdown, Menu} from "antd";
import {CaretDownOutlined} from "@ant-design/icons";

const roles = ["Montiroinq Əməliyyatçısı", "Montiroinq Təsdiqləyici", "Müşahidə Qrupunun üzvü", "Sistem Sahibi"];

export default function ProfileButton() {
    const {user} = useContext(UserContext);

    const logOut = () => {
        localStorage.clear();
        window.location.href = "https://asanloginpreprod.my.gov.az/cdsso-logout"
    }


    const menu = (
        <Menu>
            <Menu.Item onClick={logOut}>
                <Button style={{textAlign: "center", border: "none", width: "100%", background: "none", color: "orangered"}}>
                    Çıxış et
                </Button>
            </Menu.Item>
        </Menu>
    );

    return (
        <UserContextProvider>
            {
                user ?
                    <div>
                        <Dropdown trigger={['click']} overlay={menu} placement={"bottomCenter"}>
                            <Button style={{backgroundColor: "#001529", color: "white"}} type={"dashed"} size={"small"}>{`${user?.firstName} ${user?.lastName} (${roles[user.user_group - 1]})`} <CaretDownOutlined style={{color: "white"}} /></Button>
                        </Dropdown>
                    </div>
                    :
                    <></>
            }
        </UserContextProvider>
    )
}