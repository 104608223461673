import PageHeading from "../../components/PageHeading";
import {Button, Form, Input, Radio, Select, TreeSelect} from "antd";
import {PlusOutlined} from '@ant-design/icons';

import {useEffect, useState} from "react";
import {
    createOrganization,
    getOrganization,
    getOrganizations,
    getOrganizationsSelect,
    updateOrganization,
} from "../../adapters/api.adapter";
import ExtendedTable from "../../components/ExtendedTable";
import {useHistory} from "react-router-dom";
import GenericFormModal from "../../forms/genericFormModal";


const mobilePrefix = [
    "050",
    "051",
    "055",
    "060",
    "070",
    "012",
    "077",
    "099"
]

const numberPrefixSelector = (
    <Form.Item name="phone_number_prefix" noStyle>
        <Select
            placeholder={"Seç"}
            style={{
                width: 70,
            }}
            rules={[
                {
                    required: true,
                    message: "Mobil prefiks seçilməlidir!"
                }

            ]}
        >
            {
                (() => {
                    return mobilePrefix.map((d, k) => <Select.Option key={k + Math.random() * 100}
                                                                     value={d}>{d}</Select.Option>)
                })()
            }
        </Select>
    </Form.Item>
);


export default function Organizations() {
    const history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingRecordId, setEditingRecordId] = useState();
    const [ignoreRecord, setIgnoreRecord] = useState();
    const [orgs, setOrgs] = useState();
    const [isUpdated, setIsUpdated] = useState();

    useEffect(() => {
        const fetchData = async () => {
            setOrgs(await getOrganizationsSelect());
        }

        fetchData();
    }, [])

    return (
        <div>
            <PageHeading title={"Təşkilatlar"} path={"Panel/Tənzimləmələr/Təşkilatlar"}>
                <div>
                    <Button icon={<PlusOutlined/>} type={"primary"} onClick={() => {
                        setIsModalVisible(true);
                        setIgnoreRecord(true)
                    }}>
                        Əlavə et
                    </Button>
                </div>
            </PageHeading>
            <GenericFormModal
                ignoreRecord={ignoreRecord}
                recordId={editingRecordId}
                setIsModalVisible={setIsModalVisible}
                update={() => setIsUpdated(!isUpdated)}
                isModalVisible={isModalVisible}
                fields={[
                    {
                        name: ["phone_number_prefix"],
                        value: mobilePrefix[0],
                    }
                ]}
                dataEntrance={(data) => {
                    return {
                        ...data,
                        phone_number_prefix: data.phone_number ? data.phone_number.substring(0, 3) : "",
                        phone_number: data.phone_number ? data.phone_number.substring(3, 10) : "",
                    }
                }}
                dataManipulation={(data) => {
                    if(data.phone_number) data.phone_number = `${data.phone_number_prefix}${data.phone_number}`;
                    return data;
                }}
                mainFunctions={{get: getOrganization, update: updateOrganization, create: createOrganization}}>
                <Form.Item
                    name={"name"}
                    label={"Adı"}
                    rules={[
                        {
                            required: true,
                            message: "Ad daxil edin"
                        }
                    ]}
                >
                    <Input placeholder={"Təhsil Nazirliyi"}/>
                </Form.Item>
                <Form.Item
                    name="vat_number"
                    label="VÖEN"
                >
                    <Input placeHolder={"123456789"}/>
                </Form.Item>
                <Form.Item
                    name={"address"}
                    label={"Ünvan"}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name={"fax"}
                    label={"Fax"}
                >
                    <Input
                        style={{
                            width: '100%',
                        }}
                        placeholder={"0124360587"}
                    />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Elektron poçt"
                    rules={[
                        {
                            required: true,
                            message: 'Elektron poçt daxil edin!',
                        },
                    ]}
                >
                    <Input placeholder={"example@website.com"}
                    />
                </Form.Item>
                <Form.Item
                    name="phone_number"
                    label="Nömrə"
                >
                    <Input
                        addonBefore={numberPrefixSelector}
                        style={{
                            width: '100%',
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name="parent_id"
                    label="Təşkilat"
                >
                    <TreeSelect allowClear treeData={orgs} loading={!orgs} placeholder={"Təşkilatı seçin"}/>
                </Form.Item>
                {
                    (() => {
                        if (editingRecordId && !ignoreRecord) {
                            return (
                                <Form.Item
                                    name={"is_active"}
                                    label={"Statusu"}
                                >
                                    <Radio.Group>
                                        <Radio value={0}>Deaktiv</Radio>
                                        <Radio value={1}>Aktiv</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            )
                        }
                    })()
                }

            </GenericFormModal>
            <ExtendedTable
                update={isUpdated}
                history={history}
                fetchFunction={getOrganizations} actions={{
                edit: (id) => {
                    setEditingRecordId(id);
                    setIgnoreRecord(false);
                    setIsModalVisible(true);
                }
            }}/>
        </div>
    )
}