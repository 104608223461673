import {useHistory} from "react-router-dom";
import PageHeading from "../../components/PageHeading";
import {downloadReport, getMyOperations, getVerifiedTasks} from "../../adapters/api.adapter";
import ExtendedTable from "../../components/ExtendedTable";
import {Col, List, Row, Button} from "antd";
import {FileSearchOutlined, HistoryOutlined} from "@ant-design/icons";
import ExpandedRow from "../../components/ExpandedRow";


export function MyOperations() {
    const history = useHistory()

    return (
        <div>
            <PageHeading title={"Mənim Əməliyyatlarım"} path={"Panel/Hesabatlar/Əməliyyatlarım"} />
                <ExtendedTable
                    history={history}
                    fetchFunction={getMyOperations}
                    actions={{
                        custom: [
                            {
                                icon: <FileSearchOutlined/>,
                                onClick: (id, record) => {
                                    const url = new URL(window.location);
                                    const win = window.open(url.origin+"/dashboard/item/"+record.item_id, "_blank");
                                    win.focus();
                                }
                            }
                        ]
                    }}
                    expandable={{
                        expandedRowRender: record => <ExpandedRow record={record} />,
                        expandIcon: ({expanded, onExpand, record}) =>
                            <Button size={"small"} onClick={e => onExpand(record, e)}><HistoryOutlined/></Button>,
                        rowExpandable: record => record.progressLogs.length >= 0
                    }}
                />
        </div>
)
}