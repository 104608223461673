import { useHistory } from "react-router-dom";
import {
  Col,
  Form,
  Input,
  List,
  Row,
  Button,
  DatePicker,
  Typography,
  message,
  Upload,
} from "antd";
import ExtendedTable from "../../components/ExtendedTable";
import PageHeading from "../../components/PageHeading";
import {
  downloadAttachment,
  downloadImage,
  verifiedAll,
  getVerifierTask,
  getVerifierTasks,
  operateVerifierTask,
} from "../../adapters/api.adapter";
import {
  CheckOutlined,
  CloseOutlined,
  FileSearchOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import GenericFormModal from "../../forms/genericFormModal";
import { useEffect, useState } from "react";
import moment from "moment";
import ExpandedRow from "../../components/ExpandedRow";
import { saveAs } from "file-saver";

const { TextArea } = Input;
const { Text } = Typography;

export default function Verify() {
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [status, setStatus] = useState();
  const [editingRecordId, setEditingRecordId] = useState();
  const [ignoreRecord, setIgnoreRecord] = useState();
  const [isUpdated, setIsUpdated] = useState();
  const [isLoading, setLoading] = useState();

  const [fileList, setFileList] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [assignmentType, setAssignmentType] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [verifyLoading, setVerifyLoading] = useState(false);

  const downloadFile = (id, name) => {
    setLoading(true);
    downloadAttachment(id)
      .then(({ data, type }) => {
        const file = new File([data], name, { type: type });
        return saveAs(file);
      })
      .catch((e) => {
        message.error("Xəta!");
      })
      .finally(() => setLoading(false));
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedIds(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const verifedClick = () => {
    setVerifyLoading(true);
    verifiedAll(selectedIds)
      .then(() => {
        message.success("Sənədlər təsdiqləndi");
        setIsUpdated(!isUpdated);
      })
      .catch(() => {
        message.error("Xəta!");
      })
      .finally(() => {
        setVerifyLoading(false);
        setSelectedIds([]);
      });
  };

  return (
    <div>
      <PageHeading
        title={"Monitorinq Təsdiqləyici Sənədlər"}
        path={"Panel/Əməliyyatlar/Monitorinq Təsdiqləyici Sənədləri"}
      >
        {selectedIds.length === 0 ? (
          <Button onClick={() => verifedClick()} disabled>
            Seçilmişləri təsdiqlə
          </Button>
        ) : (
          <Button
            loading={verifyLoading}
            disabled={verifyLoading}
            onClick={() => verifedClick()}
          >
            Seçilmişləri təsdiqlə
          </Button>
        )}
      </PageHeading>
      <GenericFormModal
        update={() => setIsUpdated(!isUpdated)}
        ignoreRecord={ignoreRecord}
        recordId={editingRecordId}
        title={status === 0 ? "Təsdiq et" : "Rədd et"}
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        extraLoading={isLoading}
        dataManipulation={(record) => {
          const formData = new FormData();
          for (const name in record) {
            if (name !== "attachments" || !record[name])
              formData.append(name, record[name]);
          }

          formData.append("status", status == 0 ? 6 : 3);

          for (let i = 0; i < fileList.length; i++) {
            formData.append(`attachments[]`, fileList[i]);
          }
          return formData;
        }}
        dataEntrance={(record) => {
          record.pricing_date = moment(record.pricing_date);
          setAttachments(record.attachments);
          setAssignmentType(record.assignment_type);

          delete record.description;
          return record;
        }}
        ignoreRecordId
        mainFunctions={{
          get: getVerifierTask,
          update: operateVerifierTask(status),
          create: operateVerifierTask(status),
        }}
      >
        <Form.Item name={"task_id"} label={"Task kodu"}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          name={"lastPrice"}
          label={
            assignmentType === 4 ? "Maşının əvvəlki qiyməti" : "Əvvəlki qiymət"
          }
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name={"price"}
          label={
            assignmentType === 4
              ? "Maşının daxil olunan qiyməti"
              : "Daxil olunan qiymət"
          }
        >
          <Input disabled />
        </Form.Item>
        {assignmentType === 4 && (
          <>
            <Form.Item
              name={"lastSalaryPrice"}
              label={"Maşinistin əvvəlki əmək haqqı"}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name={"salary_price"}
              label={"Maşinistin daxil olunan əmək haqqı"}
            >
              <Input disabled />
            </Form.Item>
          </>
        )}

        {/*{*/}
        {/*    assignmentType === 4 && (*/}
        {/*        <Form.Item*/}
        {/*            name={"currentPrice"}*/}
        {/*            label={"İndiki qiymət"}*/}
        {/*        >*/}
        {/*            <Input disabled/>*/}
        {/*        </Form.Item>*/}
        {/*    )*/}
        {/*}*/}
        <Form.Item
          name={"description"}
          label={"Qeyd"}
          rules={
            status !== 0
              ? [
                  {
                    required: true,
                    message: "Qeyd daxil edin",
                  },
                ]
              : null
          }
        >
          <TextArea placeholder="Qeyd" autoSize={{ minRows: 3, maxRows: 5 }} />
        </Form.Item>
        <Form.Item name={"pricing_date"} label={"Qiymətləndirmə tarixi"}>
          <DatePicker
            style={{ width: "100%" }}
            disabled
            placeholder={"Vaxt seçin"}
            showTime
            format={"YYYY-DD-MM HH:mm"}
          />
        </Form.Item>
        {attachments.length !== 0 && (
          <>
            <Text style={{ fontWeight: "bold" }}>Qoşmalar</Text>
            <div>
              {attachments.map((k) => (
                <div key={k.id}>
                  <a
                    onClick={() => downloadFile(k.id, k.name)}
                    style={{ padding: 0, margin: 0 }}
                  >
                    {k.name} - {k.uploaded_by} tərəfindən yükləndi
                  </a>
                </div>
              ))}
            </div>
          </>
        )}
        <Form.Item
          name="attachments"
          label="Qoşma əlavə edin"
          getValueFromEvent={({ file }) => file.originFileObj}
        >
          <Upload
            maxCount={1}
            fileList={fileList}
            beforeUpload={(file, fileList) => {
              setFileList(fileList);
            }}
            onRemove={(e) => {
              setFileList(fileList.filter((k) => k.uid !== e.uid));
            }}
            multiple
          >
            <Button type={"upload"}>Yüklə</Button>
          </Upload>
        </Form.Item>
      </GenericFormModal>
      <ExtendedTable
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        history={history}
        update={isUpdated}
        fetchFunction={getVerifierTasks}
        actions={{
          custom: [
            {
              icon: <CheckOutlined style={{ color: "limegreen" }} />,
              onClick: (id) => {
                setStatus(0);
                setEditingRecordId(id);
                setIgnoreRecord(false);
                setIsModalVisible(true);
              },
            },
            {
              icon: <CloseOutlined style={{ color: "orangered" }} />,
              onClick: (id) => {
                setStatus(1);
                setEditingRecordId(id);
                setIgnoreRecord(false);
                setIsModalVisible(true);
              },
            },
            {
              icon: <FileSearchOutlined />,
              onClick: (id, record) => {
                const url = new URL(window.location);
                const win = window.open(
                  url.origin + "/dashboard/item/" + record.item_id,
                  "_blank"
                );
                win.focus();
              },
            },
          ],
        }}
        expandable={{
          expandedRowRender: (record) => <ExpandedRow record={record} />,
          expandIcon: ({ expanded, onExpand, record }) => (
            <Button size={"small"} onClick={(e) => onExpand(record, e)}>
              <HistoryOutlined />
            </Button>
          ),
          rowExpandable: (record) => record.progressLogs.length >= 0,
        }}
      />
    </div>
  );
}
