import Chart from "../../components/Chart";
import PageHeading from "../../components/PageHeading";
import {Col, Row, Typography, DatePicker, Select, Button, Skeleton, Form, message} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined} from '@ant-design/icons';
import ecoDistricts from '../../assets/ecoDistricts';
import PriceComp from "../../components/PriceComp";
import VersionControlRow from "../../components/VersionControlRow";
import {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {downloadSingleItemReport, getItemSingle} from "../../adapters/api.adapter";
import useForm from "antd/es/form/hooks/useForm";
import qs from "qs";
import dayjs from "dayjs";
import moment from "moment";
import ExportButton from "../../components/ExportButton";

const {RangePicker} = DatePicker;
const {Text} = Typography;

const rowStyle = {marginTop: "10px"}
const percentDiff = (a, b) => (b - a) / a * 100;

const versionControlBuilder = (data, riskPercent, assignmentType) => {
    return Object.keys(data).map((date, key) => {
        const diff = percentDiff(data[date][data[date].length - 1]?.prevPrice, data[date][0]?.currentPrice)

        if (data[date].length === 0) return <></>

        return (
            <Row style={rowStyle}>
                <Text style={{fontWeight: "bold"}}>{date} tarixində qiymət dəyişməsi faizi
                    - {
                        (() => {
                            if (isFinite(diff)) {
                                return <Text style={{color: Math.abs(diff) > riskPercent ? "orangered" : "limegreen"}}>{
                                    diff > 0 ? <ArrowUpOutlined/> : <ArrowDownOutlined/>
                                }{diff.toFixed(3)}%</Text>
                            }
                            return "N/A"
                        })()
                    }
                </Text>
                <Col span={24}>
                    {
                        data[date].map((el, k) => <VersionControlRow key={k} date={el.verifiedDate} updatingUser={el.updateUser} updateUser_id={el.updateUser_id}
                                                                     prevPrice={el.prevPrice}
                                                                     currentPrice={el.currentPrice}
                                                                     prevSalaryPrice={el.prevSalary}
                                                                     currentSalaryPrice={el.salary_price}
                                                                     riskPercent={riskPercent} progressLogs={el.progressLogs} type={assignmentType}/>)
                    }
                </Col>
            </Row>
        )
    })
}


export default function Item() {
    const history = useHistory();
    const [form] = useForm();
    const [mainData, setMainData] = useState();
    const [chartData, setChartData] = useState();
    const [isInitialLoading, setInitialLoading] = useState(true);
    const [name, setName] = useState();
    const [fieldCode, setFieldCode] = useState();
    const [changeCard, setChangeCard] = useState();
    const [filters, setFilters] = useState({region: 0});
    const [riskPercent, setRiskPercent] = useState(0);
    const [exportShown, setExportShown] = useState(true);
    const [assignmentType, setAssignmentType] = useState(0);
    const [salaryChangeCard, setSalaryChangeCart] = useState();

    const [pagination, setPagination] = useState({
        yearStart: dayjs().subtract(1, 'year').format("YYYY-MM-DD"),
        yearEnd: dayjs().format("YYYY-MM-DD")
    });


    let {id} = useParams();

    const fetch = (id, params) => {
        setInitialLoading(true);
        getItemSingle(id, params).then(k => {
            setMainData(k.data);
            setChartData(k.chartData);
            setName(k.name);
            setAssignmentType(k.assignment_type);
            setChangeCard(k.totalCurrentPriceChangeCard);
            setSalaryChangeCart(k.totalCurrentSalaryChangeCard)
            setFieldCode(k.field_code);
            setRiskPercent(k.suspicious_percentage);

        }).finally(() => setInitialLoading(false));
    }

    const clear = () => {
        const pag = {
            yearStart: dayjs().subtract(1, 'year').format("YYYY-MM-DD"),
            yearEnd: dayjs().format("YYYY-MM-DD")
        }
        const fil = {region: 0}

        setFilters(fil);
        setPagination(pag);
        setExportShown(true);


        form.setFieldsValue({
            ...fil,
            range: [moment(dayjs().subtract(1, 'year').format("YYYY-MM-DD")), moment(dayjs().format("YYYY-MM-DD"))]
        });
        fetch(id, {...fil, ...pag})
    }

    useEffect(() => {
        const url = new URL(window.location);
        const parsed = qs.parse(url.search.substring(1, url.search.length));

        let pag = {
            yearStart: parsed.yearStart || pagination.yearStart,
            yearEnd: parsed.yearEnd || pagination.yearEnd,
        };

        let fil = {
            region: parseInt(parsed.region) || filters.region
        }

        setFilters(fil);
        setPagination(pag);
        form.setFieldsValue({...fil, range: [moment(pag.yearStart), moment(pag.yearEnd)]});

        fetch(id, {...fil, ...pag});
    }, []);


    const search = () => {
        const result = form.getFieldsValue();

        const fil = {region: result.region};
        const pag = {
            yearStart: dayjs(result.range[0]).format("YYYY-MM-DD"),
            yearEnd: dayjs(result.range[1]).format("YYYY-MM-DD")
        };

        fetch(id, {...fil, ...pag})
        setFilters(fil);
        setPagination(pag);
        setExportShown(true);
    }

    useEffect(() => {
        if (filters || pagination) {

            const lk = {...filters, ...pagination}

            for (const [key, value] of Object.entries(lk)) {
                if (lk[key] === undefined || lk[key] === "") delete lk[key];
            }

            history.replace(decodeURIComponent(`${window.location.pathname}?${qs.stringify(lk, {arrayFormat: 'brackets'})}`));
        }
    }, [filters, pagination])

    return (
        <div>
            <PageHeading title={isInitialLoading ? "Yüklənir" : `Sahə kodu: ${fieldCode}`} loading={isInitialLoading}
                         path={`Panel/${name?.split("/")[0]}/Sahə kodu: ${fieldCode}`}/>
            <Form layout={'vertical'} form={form} name={"item-search"} onValuesChange={() => {
                setExportShown(false)
            }}
                  scrollToFirstError>
                <Row gutter={4} span={24}>
                    <Col span={exportShown ? 6 : 9}>
                        <Form.Item name={"range"}>
                            <RangePicker format={"YYYY-MM-DD"} placeholder={["Başlanğıc", "Son"]} allowClear
                                         style={{width: "100%"}} disabledDate={(current) => {
                                const now = moment();
                                return current > now;
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={"region"}>
                            <Select allowClear style={{width: "100%"}} placeholder={"Iqtisadi region"}
                                    options={ecoDistricts}/>
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Button style={{width: "100%"}} type={"primary"} loading={isInitialLoading} onClick={search}>
                            Axtar
                        </Button>
                    </Col>
                    <Col span={3}>
                        <Button onClick={clear} style={{width: "100%"}}>
                            Təmizlə
                        </Button>
                    </Col>
                    <Col span={3}>
                        <Button style={{width: "100%"}} onClick={() => {
                            navigator.clipboard.writeText(window.location.href);
                            message.info(`Filter kopyalandı`)
                        }}
                        > Filterləri kopyala</Button>
                    </Col>
                    {
                        exportShown ? <Col span={3}>
                            <ExportButton get={() => downloadSingleItemReport(id, {...filters, ...pagination})}/>
                        </Col> : <></>
                    }
                </Row>
            </Form>
            <Row style={rowStyle}>
                <Col span={24}>
                    {
                        isInitialLoading ? <></> :
                            <Chart data={chartData} type={assignmentType}/>
                    }
                </Col>
            </Row>
            <Row style={rowStyle} gutter={15}>
                <Col span={12}>
                    <Text strong>Adı</Text>
                </Col>
                <Col span={12}>
                    <Text strong>{assignmentType === 4 ? "Maşının ümumi qiymət dəyişimi" : "Ümumi cari qiymət dəyişimi"}</Text>
                </Col>
            </Row>
            <Row style={rowStyle} gutter={15}>
                <Col span={12}>
                    <div style={{padding: "10px", backgroundColor: "white", border: "1px solid #ccc"}}>
                        {
                            isInitialLoading ? <Skeleton active paragraph={{rows: 3}}/> :
                                <Text style={{fontSize: "18px"}}>{name?.split("/")[1]}</Text>
                        }
                    </div>
                </Col>
                <Col span={12}>
                    <PriceComp loading={isInitialLoading} p1={changeCard?.totalChanged}
                               p2={changeCard?.totalDifference} p3={changeCard?.currentPrice} riskPercent={riskPercent}/>
                    {
                        assignmentType === 4 && (
                            <div style={{marginTop: "10px"}}>
                                <Text strong>Maşinistin ümumi əmək haqqı dəyişimi</Text>
                                <div style={{marginTop: "10px"}}>
                                    <PriceComp loading={isInitialLoading} p1={changeCard?.totalChanged}
                                               p2={salaryChangeCard?.totalDifference} p3={parseFloat(salaryChangeCard?.currentPrice)} riskPercent={riskPercent}/>
                                </div>
                            </div>
                        )
                    }
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    {
                        isInitialLoading ? <></> : (versionControlBuilder(mainData, riskPercent, assignmentType))
                    }
                </Col>
            </Row>
        </div>
    )
}