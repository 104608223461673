import {Button, Col, List, message, Popover, Row, Typography} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined, ArrowRightOutlined, HistoryOutlined} from '@ant-design/icons';
import {useState} from "react";
import TweenOne from "rc-tween-one";
import PopoverUserToolTip from "./PopoverUserToolTip";
import {downloadAttachment} from "../adapters/api.adapter";
import {saveAs} from 'file-saver'
import {anyScopeRegex} from "simple-markdown";

const {Text} = Typography

const subtitleStyle = {color: "#ccc", fontSize: "12px"}
const percentDiff = (a, b) => (b - a) / a * 100;

export default function VersionControlRow({
                                              updatingUser,
                                              updateUser_id,
                                              date,
                                              prevPrice,
                                              currentPrice,
                                              riskPercent,
                                              progressLogs,
                                              prevSalaryPrice,
                                              currentSalaryPrice,
                                              type = 0
                                          }) {
    const [isExpanded, setIsExpanded] = useState();

    const downloadFile = (id, name) => {
        downloadAttachment(id).then(({data, type}) => {
            const file = new File([data], name, {type: type});
            return saveAs(file);
        }).catch(e => {
            message.error("Xəta!")
        })
    }

    const pp = percentDiff(prevPrice, currentPrice);
    let pp2 = 0;
    if (type === 4) {
        pp2 = percentDiff(prevSalaryPrice, currentSalaryPrice);
    }

    return (
        <TweenOne
            animation={{
                maxHeight: !isExpanded ? type === 4 ? "80px" : "55px" : "4000px",
                duration: "600"
            }}
            component={"div"}
            style={{
                backgroundColor: "white",
                marginTop: "6px",
                border: "1px solid #ccc",
                padding: "8px"
            }}>

            <Row>
                <Col span={24}>
                    <Row style={{alignItems: "center"}}>
                        <Col span={1} style={{textAlign: "center", cursor: "pointer"}}
                             onClick={() => setIsExpanded(!isExpanded)}>
                            <Button size={"small"}><HistoryOutlined/></Button>
                        </Col>
                        <Col span={5}>
                            {updateUser_id ? (
                                <Popover title={updatingUser}
                                         content={() => <PopoverUserToolTip id={updateUser_id}/>}>
                                    <Text>{updatingUser}</Text>
                                </Popover>
                            ) : <Text>{updatingUser}</Text>}
                        </Col>
                        <Col span={6} style={{textAlign: "center"}}>
                            <Text>{date}</Text>
                        </Col>
                        <Col span={6} style={{textAlign: "center"}}>
                            <div>
                                <Text style={{marginRight: "10px"}}>
                                    Qiymət:
                                </Text>
                                <Text style={{fontSize: "12px"}}>₼{prevPrice}<span
                                    style={{padding: "2px"}}><ArrowRightOutlined
                                    style={{color: Math.abs(pp2) > riskPercent ? "orangered" : "limegreen"}}/></span>₼{currentPrice}
                                </Text>
                            </div>
                            {
                                type === 4 && (
                                    <div>
                                        <Text style={{marginRight: "10px"}}>
                                            Maşinistin əmək haqqı:
                                        </Text>
                                        <Text style={{fontSize: "12px"}}>₼{prevSalaryPrice}<span
                                            style={{padding: "2px"}}><ArrowRightOutlined
                                            style={{color: Math.abs(pp2) > riskPercent ? "orangered" : "limegreen"}}/></span>₼{currentSalaryPrice}
                                        </Text>
                                    </div>
                                )
                            }
                        </Col>
                        <Col span={6} style={{textAlign: "center"}}>
                            <div>
                                {
                                    isFinite(pp) ? <Text
                                        style={{color: Math.abs(pp) > riskPercent ? "orangered" : "limegreen"}}>{
                                        pp > 0 ? <ArrowUpOutlined/> : <ArrowDownOutlined/>
                                    }{pp.toFixed(3)}%</Text> : "N/A"
                                }
                            </div>
                            {
                                type === 4 && (
                                    <div>
                                        {
                                            isFinite(pp2) ? <Text
                                                style={{color: Math.abs(pp2) > riskPercent ? "orangered" : "limegreen"}}>{
                                                pp2 > 0 ? <ArrowUpOutlined/> : <ArrowDownOutlined/>
                                            }{pp2.toFixed(3)}%</Text> : "N/A"
                                        }
                                    </div>
                                )
                            }
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row>
                        <Col span={1} style={{textAlign: "center"}}>
                            <div style={subtitleStyle}>
                                <Text style={subtitleStyle}>Tarixçə</Text>
                            </div>
                        </Col>
                        <Col span={5}>
                            <div style={subtitleStyle}>
                                <Text style={subtitleStyle}>Adı, soyadı, atasının adı</Text>
                            </div>
                        </Col>
                        <Col span={6} style={{textAlign: "center"}}>
                            <div style={subtitleStyle}>
                                <Text style={subtitleStyle}>Tarix</Text>
                            </div>
                        </Col>
                        <Col span={6} style={{textAlign: "center"}}>
                            <div style={subtitleStyle}>
                                <Text style={subtitleStyle}>Əvvəlki qiymət <ArrowRightOutlined/>Cari qiymət</Text>
                            </div>
                        </Col>
                        <Col span={6} style={{textAlign: "center"}}>
                            <div style={subtitleStyle}>
                                <Text style={subtitleStyle}>Qiymət fərqi</Text>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <TweenOne component={"div"} animation={{
                opacity: !isExpanded ? 0 : 1, duration: "800"
            }}>
                <List
                    size="small"
                    header={<div>Tarixçə</div>}
                    emptyText={"Məlumat yoxdur"}
                    bordered
                    dataSource={progressLogs}
                    renderItem={item => <List.Item>
                        <Row style={{width: "100%"}}>
                            <Col span={4}>
                                {item.createdAt}
                            </Col>
                            <Col span={4}>
                                {item.operatedBy}
                            </Col>
                            <Col span={8}>
                                {item.description}
                            </Col>
                            <Col span={8}>
                                {
                                    item.attachments.map(k => <div key={k.id}><a
                                        onClick={() => downloadFile(k.id, k.name)}
                                        style={{padding: 0, margin: 0}}>{k.name} - {k.uploaded_by} tərəfindən
                                        yükləndi</a>
                                    </div>)
                                }
                            </Col>
                        </Row>
                    </List.Item>}
                />
            </TweenOne>
        </TweenOne>
    )
}
