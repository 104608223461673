import PageHeading from "../../components/PageHeading";
import {
    Button,
    Col,
    Form,
    Input,
    Select,
    TimePicker,
    Row,
    InputNumber,
    Card,
    Typography,
    Table,
    message,
} from "antd";
import React, {useEffect, useState} from "react";
import {EditOutlined} from "@ant-design/icons";
import {
    getEmailTemplate,
    getEmailTemplates, getSettings,
    updateEmailTemplate, updateSettings,
} from "../../adapters/api.adapter";
import GenericFormModal from "../../forms/genericFormModal";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import ErrorHolder from "../../components/ErrorHolder";

const {Text} = Typography;

const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 8},
};
const tailLayout = {
    marginTop: "10px",
    wrapperCol: {offset: 6, span: 8},
};

export default function AllSettings() {
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [emails, setEmails] = useState();
    const [templatesLoading, setTemplatesLoading] = useState(true);
    const [emailSettingsLoading, setEmailSettingsLoading] = useState(true);
    const [variables, setVariables] = useState();
    const [editingRecord, setEditingRecordId] = useState();
    const [ignoreRecord, setIgnoreRecord] = useState();
    const [isModalVisible, setIsModalVisible] = useState();
    const [update, setUpdate] = useState();
    const [emailPostError, setEmailPostError] = useState();
    const [applicationSettingsLoading, setApplicationSettingsLoading] = useState(true);
    const [applicationSettingsPostError, setApplicationSettingsPostError] = useState();

    const columns = [
        {
            title: "Açar söz",
            dataIndex: "key",
            key: "key",
            render: (text) => <a>{text}</a>
        },
        {
            title: "Açıqlama",
            dataIndex: "meaning",
            key: "meaning",
        }
    ]

    const fetchEmails = () => {
        setTemplatesLoading(true);
        getEmailTemplates().then(k => setEmails(k)).finally(() => setTemplatesLoading(false));
    }

    const fetchApplicationSettings = () => {
        setApplicationSettingsLoading(true);
        getSettings("averageCalculationDate").then(k => {
            form2.setFieldsValue(k)
        }).finally(() => setApplicationSettingsLoading(false));
    }

    const setApplicationSettings = async () => {
        setApplicationSettingsLoading(true);

        try {
            await form2.validateFields();

            try {
                await updateSettings("averageCalculationDate", form2.getFieldsValue())
                setApplicationSettingsLoading(false);
                message.success("Məlumat yeniləndi");

            } catch (e) {
                setApplicationSettingsPostError(e);
                setApplicationSettingsLoading(false);
                message.error("Məlumat yeniləndi");
            }
        } catch (e) {
            setApplicationSettingsPostError(e);
            setApplicationSettingsLoading(false);
            message.error("Daxil olunan məlumatı yoxlayın");
        }
    }

    const fetchEmailSettings = () => {
        setEmailSettingsLoading(true);
        getSettings("smtp").then(k => {
            k.send_time = moment(k.send_time, "HH:mm")
            form1.setFieldsValue(k);
        }).finally(() => setEmailSettingsLoading(false));
    }

    const setEmailSettings = async () => {
        setEmailSettingsLoading(true);

        try {
            await form1.validateFields();

            try {
                await updateSettings("smtp", form1.getFieldsValue())
                setEmailSettingsLoading(false);
                message.success("Məlumat yeniləndi");

            } catch (e) {
                setEmailPostError(e);
                setEmailSettingsLoading(false);
                message.error("Məlumat yeniləndi");
            }
        } catch (e) {
            setEmailSettingsLoading(false);
            message.error("Daxil olunan məlumatı yoxlayın");
        }
    }

    useEffect(() => {
        fetchEmails();
        fetchEmailSettings();
        fetchApplicationSettings();
    }, [update])

    return (
        <div>
            <PageHeading title={"Ümumi tənzimləmələri"} path={"Panel/Tənzimləmələr/Ümumi tənzimləmələri"}/>
            <GenericFormModal
                update={() => setUpdate(!update)}
                ignoreRecord={ignoreRecord}
                recordId={editingRecord}
                setIsModalVisible={setIsModalVisible}
                isModalVisible={isModalVisible}
                dataEntrance={(record) => {
                    setVariables(record.keywords);
                    return record;
                }}
                mainFunctions={{get: getEmailTemplate, update: updateEmailTemplate}}
            >
                <Form.Item
                    name="name"
                    label="Şablon adı"
                    rules={[
                        {
                            required: true,
                            message: 'Şablon adı daxil edin!',
                        },
                    ]}
                >
                    <Input
                        placeHolder={"Ad yazın"}
                        style={{
                            width: '100%',
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name="title"
                    label="Mövzu"
                    rules={[
                        {
                            required: true,
                            message: 'Mövzu daxil edin!',
                        },
                    ]}
                >
                    <Input
                        placeHolder={"Mövzu"}
                        style={{
                            width: '100%',
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name="body"
                    label="Məzmun"
                    rules={[
                        {
                            required: true,
                            message: 'Mövzu daxil edin!',
                        },
                    ]}
                >
                    <TextArea
                        placeHolder={"Mövzu"}
                        style={{
                            width: '100%',
                        }}
                    />
                </Form.Item>
                {

                    variables ? <Table className={"not-hundred"} style={{marginTop: "20px", maxWidth: "300px"}}
                                       pagination={false} columns={columns}
                                       dataSource={variables}/> : <></>

                }
            </GenericFormModal>
            <div>
                <h4 style={{fontWeight: "bold"}}>SMTP</h4>
                <Row style={{flexWrap: 'no-wrap'}}>
                    <Col span={16}>
                        <Form {...layout} onFinish={setEmailSettings} form={form1} name={"email-settings"}
                              scrollToFirstError>
                            <Form.Item
                                name={"sender_email"}
                                label={"Göndərənin emaili"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Göndərənin emailini daxil edin!"
                                    }
                                ]}
                            >
                                <Input disabled={emailSettingsLoading} placeholder={"example@domin.com"}
                                       type={"email"}/>
                            </Form.Item>
                            <Form.Item
                                name={"sender_name"}
                                label={"Göndərənin adı"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Göndərənin adını qeyd edin!"
                                    }
                                ]}
                            >
                                <Input disabled={emailSettingsLoading} placeholder={"Ad Soyad"}/>
                            </Form.Item>
                            <Form.Item
                                name={"host"}
                                label={"Host"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Host daxil edin!"
                                    }
                                ]}
                            >
                                <Input disabled={emailSettingsLoading} placeholder={"example.com"}/>
                            </Form.Item>
                            <Form.Item
                                name={"encryption"}
                                label={"Encryption"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Encryption seçin"
                                    }
                                ]}
                            >
                                <Select placeholder={"Seç"} disabled={emailSettingsLoading}>
                                    <Select.Option value={'ssl'}>
                                        SSL
                                    </Select.Option>
                                    <Select.Option value={'tls'}>
                                        TLS
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={"port"}
                                label={"Port"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Port daxil edin"
                                    }
                                ]}
                            >
                                <InputNumber disabled={emailSettingsLoading} style={{width: '100%'}} placeholder={"465"}
                                             type={"number"}/>
                            </Form.Item>
                            <Form.Item
                                name={"username"}
                                label={"İstifadəçi adı"}
                                rules={[
                                    {
                                        required: true,
                                        message: "İstifadəçi adı daxil edin"
                                    }
                                ]}
                            >
                                <Input disabled={emailSettingsLoading} placeholder={"example"}/>
                            </Form.Item>
                            <Form.Item
                                name={"password"}
                                label={"Şifrə"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Şifrə daxil edin"
                                    }
                                ]}
                            >
                                <Input disabled={emailSettingsLoading} type={"password"}/>
                            </Form.Item>
                            <Form.Item
                                name={"send_time"}
                                label={"Email göndərilmə vaxtı"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Email göndərilmə vaxtı təyin edin"
                                    }
                                ]}
                            >
                                <TimePicker disabled={emailSettingsLoading} format={"HH:mm"}
                                            placeholder={"Vaxtı seçin"}/>
                            </Form.Item>
                            <Form.Item {...tailLayout}>
                                <Button onClick={setEmailSettings} disabled={emailSettingsLoading}
                                        style={{marginTop: "10px"}} type={"primary"}
                                        loading={emailSettingsLoading}>Yadda saxla</Button>
                            </Form.Item>
                            <Form.Item style={{marginTop: "10px"}}>
                                {
                                    emailPostError ? <ErrorHolder error={emailPostError}/> : <></>
                                }
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
            <div style={{marginTop: "10px"}}>
                <h4 style={{fontWeight: "bold"}}>Email şablonlar</h4>
                <Row span={24} gutter={12}>
                    {
                        !templatesLoading ? emails?.map(k => (
                            <Col span={4}>
                                <Card
                                    style={{width: "100%", marginTop: 16}}
                                    actions={[
                                        <EditOutlined key="edit" onClick={() => {
                                            setEditingRecordId(k.key);
                                            setIgnoreRecord(false);
                                            setIsModalVisible(true);
                                        }}/>,
                                    ]}
                                >
                                    <div style={{minHeight: "85px"}}>
                                        <Text>{k.name.trim().length > 50 ? k.name.trim().substr(0, 50) + "..." : k.name.trim()}</Text>
                                    </div>
                                </Card>
                            </Col>
                        )) : <></>
                    }
                </Row>
            </div>
            <div style={{marginTop: "10px"}}>
                <h4 style={{fontWeight: "bold"}}>Proqram təminatı tənzimləməri</h4>
                <Row style={{flexWrap: 'no-wrap'}}>
                    <Col>
                        <Form {...layout} layout={'inline'} onFinish={setApplicationSettings} form={form2}
                              name={"application-settings"}
                              scrollToFirstError>
                            <Form.Item
                                name={"days"}
                                label={"Orta qiymət hesablama gün sayı"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Məlumat daxil edin!"
                                    }
                                ]}
                                labelCol={20}
                            >
                                <InputNumber min={1} max={1000000} disabled={applicationSettingsLoading}/>
                            </Form.Item>
                            <Form.Item>
                                <Button onClick={setApplicationSettings} disabled={applicationSettingsLoading}
                                        type={"primary"}
                                        loading={applicationSettingsLoading}>Yadda saxla</Button>
                            </Form.Item>
                            <Form.Item style={{marginTop: "10px"}}>
                                {
                                    emailPostError ? <ErrorHolder error={applicationSettingsPostError}/> : <></>
                                }
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
        </div>
    )
}