import { Spin } from "antd";
import { useState } from "react";
import Modal from "antd/es/modal/Modal";

export default function ExportExcelModal({
  title,
  isModalVisible,
  setIsModalVisible,
  recordId = null,
  onClose = () => {},
  onSubmit = () => {},
  formIsValid,
  children,
  load,
  loading
}) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      confirmLoading={isLoading}
      title={title}
      visible={isModalVisible}
      cancelText={"Bağla"}
      maskClosable={false}
      keyboard={false}
      okButtonProps={{
        disabled: !formIsValid || loading,
        loading: loading
      }}
      okText={'Yüklə'}
      onOk={onSubmit}
      onCancel={(e) => {
        onClose();
        setIsModalVisible(false);
      }}
    >
      {loading && <Spin tip="Yüklənir..." spinning={loading}>
        {children}
      </Spin> || children}
    </Modal>
  );
}