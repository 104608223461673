import React, {useEffect} from 'react';
import "./index.css";
import {Button, Col, Row, Typography} from "antd";
import logo from "../../assets/logo_black.svg";
import illus from '../../assets/Sentiment analysis-amico 1.svg';
import {RightOutlined} from "@ant-design/icons";

const {Title} = Typography;

export default function Index({handleLogin}) {

    useEffect(() => {
        document.title = "Giriş" + " | Smeta Normativ Qiymətqoyma";
    }, [])


    return (
        <div style={{width: "70%", marginLeft: "auto", marginRight: "auto"}}>
            <Row span={24}>
               <Col>
                   <div style={{width: '128px', marginLeft: "-10px"}} className="logo"><img alt={"SNQ Logo"} width={"100%"} src={logo}/></div>
               </Col>
               <Col>

               </Col>
            </Row>
            <Row style={{marginTop: "90px"}} span={24}>
                <Col span={12}>
                    <div style={{marginTop: "140px"}}>
                        <h2 className={"landing-font"} style={{lineHeight: "0", marginBottom: "80px"}}>SMETA NORMATİV QİYMƏTLƏNDİRMƏ</h2>
                        <h1 className={"landing-font"} style={{fontSize: "58px", lineHeight: "0", marginBottom: "105px"}}>İNFORMASİYA</h1>
                        <h1 className={"landing-font"} style={{fontSize: "100px", lineHeight: "0", marginBottom: "80px"}}>SİSTEMİ</h1>
                        <div onClick={handleLogin} className={"my-button"}>
                            <div style={{display: "grid", gridTemplateColumns: "5fr 1fr"}}>
                                <div style={{fontSize: "15px"}} className={"rubic-font"}>
                                    Daxil ol
                                </div>
                                <div>
                                    <RightOutlined style={{backgroundColor: "white", borderRadius: "50%", padding: "5px", color: "rgb(54,111,233)"}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={12} style={{textAlign: "right"}}>
                    <img width={"75%"} src={illus} />
                </Col>
            </Row>
        </div>
    )
}