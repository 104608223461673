import React, {useContext, useState, useEffect} from 'react';
import {
    UserOutlined,
    DatabaseOutlined,
    HomeOutlined,
    SettingOutlined,
    AppstoreOutlined,
    AuditOutlined,
    BookOutlined,
    FileDoneOutlined,
    WalletOutlined,
    FormOutlined,
    FileProtectOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';

import {
    FaWeightHanging,
    MdUpdate,
    AiFillApi,
    FaShuttleVan,
    FaTruckLoading,
    CgFileDocument,
    GiWoodenCrate,
    CgOrganisation,
    AiFillMail,
    VscGroupByRefType,
} from 'react-icons/all';

import {Route, useHistory} from "react-router-dom";
import Users from '../pages/users/users';
import Weight from '../pages/weights/Weights';
import {Button, Col, Layout as LayoutAnt, Menu, Modal, Row} from 'antd';
import Organizations from "../pages/organizations/Organizations";
import Index from "../pages/home";
import ApiKey from "../pages/api/ApiKey";
import AllSettings from "../pages/settings/allSettings";
import logo from '../assets/logo.svg';
import Documents from "../pages/documents/documents";
import Verify from "../pages/verify/verify";
import ProfileButton from "./ProfileButton";
import UserContextProvider, {UserContext} from "../contexts/user.context";
import Audit from "../pages/audit/audit";
import Assignments from "../pages/assignments/assignments";
import EmailNotifications from "../pages/settings/email-notifications";
import Item from "../pages/item/item";
import {Footer} from "antd/es/layout/layout";
import Reports from "../pages/reports/reports";
import Nomenclature from "../pages/nomenclature/nomenclature";
import Items from "../pages/items/items";
import {MyOperations} from "../pages/operations/my-operations";
import {OrgOperations} from "../pages/operations/org-operations";
import TweenOne from 'rc-tween-one';
import Category from "../pages/category/category";

const {SubMenu} = Menu;


const {Header, Content, Sider} = LayoutAnt;

export default function Layout({children}) {
    const {user} = useContext(UserContext);
    const [collapsed, setCollapsed] = useState(false);
    const [videoTutorialVisible, setVideoTutorialVisible] = useState(false);
    const [videoSource, setVideoSource] = useState();
    let history = useHistory();


    const videoFrame = <iframe
        width="100%"
        height="500px"
        src={`https://www.youtube.com/embed/${videoSource}`}
        frameBorder="0"
        allow=""
        allowFullScreen
        title={'Video Təlimat'}
    />;

    useEffect(() => {

        if(!user)
            return;
        if(user.user_group == 4) {
            setVideoSource('r3qoEnb_6P8');
        }
        if(user.user_group == 3) {
            setVideoSource('QH22F8Wa1cc');
        }
        if(user.user_group == 2) {
            setVideoSource('YuutC2f3Sgk');
        }
        if(user.user_group == 1) {
            setVideoSource('sPY8O0tS0oU');
        }

    }, [user])

    return (
        <div style={{height: 'calc(100vh-55px)'}}>
            <LayoutAnt style={{height: "100vh"}}>
                <Sider collapsible collapsed={collapsed} style={{overflowY: "scroll"}}
                       onCollapse={() => setCollapsed(!collapsed)}  width={250}
                       className="site-layout-background">
                    <TweenOne
                        animation={{width: collapsed ? 80 : 250, duration: 300}}
                        component={"div"}
                        style={{position: "fixed", top: 0, zIndex: 10, backgroundColor: "#001529"}}
                    >
                        <div style={{width: '64px', height: "63px", marginLeft: "auto", marginRight: "auto"}}
                             className="logo"><img alt={"SNQ Logo"} width={"100%"} src={logo}/>
                        </div>
                    </TweenOne>
                    <div style={{}}>

                    </div>
                    <Menu
                        mode="inline"
                        theme="dark"
                        style={{marginTop: "60px", paddingBottom: "60px"}}
                        inlineCollapsed={collapsed}
                    >
                        <Menu.Item icon={<HomeOutlined/>} onClick={() => history.push("/dashboard")}
                                   title={'Ana səhifə'} key={0}>
                            Ana səhifə
                        </Menu.Item>
                        <SubMenu icon={<MdUpdate/>} title={'Əməliyyatlar'} key={2}>
                            {
                                user && (user.user_group === 1 || user.user_group === 4) ? (
                                    <Menu.Item icon={<BookOutlined/>}
                                               onClick={() => history.push("/dashboard/documents")}
                                               key={1}>
                                        Sənədlər
                                    </Menu.Item>) : <></>
                            }
                            {
                                user && (user.user_group === 2 || user.user_group === 4) ? (
                                    <Menu.Item icon={<FileDoneOutlined/>}
                                               onClick={() => history.push("/dashboard/verify")}
                                               key={2}>
                                        Təsdiqləmələr
                                    </Menu.Item>
                                ) : <></>
                            }
                            {
                                user && (user.user_group === 3 || user.user_group === 4) ? (
                                    <Menu.Item icon={<AuditOutlined/>}
                                               onClick={() => history.push("/dashboard/audit")}
                                               key={3}>
                                        Müşahidə
                                    </Menu.Item>
                                ) : <></>
                            }
                        </SubMenu>
                        <SubMenu icon={<CgFileDocument/>} title={'Hesabatlar'} key={4}>
                            <Menu.Item icon={<FileProtectOutlined/>} key={5}
                                       onClick={() => history.push("/dashboard/my-reports")}
                                       title={'Mənim əməliyyatlarım'}>
                                Mənim əməliyyatlarım
                            </Menu.Item>
                            <Menu.Item icon={<FileProtectOutlined/>} key={6}
                                       onClick={() => history.push("/dashboard/organization-reports")}
                                       title={'Təşkilatın əməliyyat tarixçəsi'}>
                                Təşkilatın əməliyyat tarixçəsi
                            </Menu.Item>
                            <Menu.Item icon={<FileProtectOutlined/>} key={7}
                                       onClick={() => history.push("/dashboard/reports")}
                                       title={'Təsdiqlənmiş qiymətlər'}>
                                Təsdiqlənmiş qiymətlər
                            </Menu.Item>
                            {
                                user && user.user_group === 4 ? <Menu.Item title={'Email xəbərdarlıqlar'} icon={<AiFillMail/>}
                                           onClick={() => history.push("/dashboard/email")}
                                           key={8}>Email xəbərdarlıqlar</Menu.Item> : <></>
                            }
                            <SubMenu icon={<DatabaseOutlined/>} title={'Toplu Hesabat'} key={100}>
                                <Menu.Item icon={<GiWoodenCrate/>} key={9}
                                           onClick={() => history.push("/dashboard/items/1")}
                                           title={'Tikintidə istifadə edilən materiallar, məmulatlar, konstruksiyalar, mebel - inventar və avadanlıqlar üzrə məlumatlar'}>

                                    Tikintidə istifadə edilən materiallar, məmulatlar, konstruksiyalar, mebel - inventar
                                    və
                                    avadanlıqlar üzrə məlumatlar

                                </Menu.Item>
                                <Menu.Item icon={<FaShuttleVan/>} key={10}
                                           onClick={() => history.push("/dashboard/items/2")}
                                           title={'Tikinti maşın - mexanizmlərin və avtonəqliyyat vasitələrinin istismar xərcləri barədə məlumatlar'}>
                                    Tikinti maşın - mexanizmlərin və avtonəqliyyat vasitələrinin istismar xərcləri
                                    barədə
                                    məlumatlar
                                </Menu.Item>
                                <Menu.Item icon={<WalletOutlined/>}
                                           title={"Tikintidə əməyin ödənilməsinə aid məlumatlar"}
                                           onClick={() => history.push("/dashboard/items/3")}
                                           key={11}>
                                    Tikintidə əməyin ödənilməsinə aid məlumatlar
                                </Menu.Item>
                                <Menu.Item icon={<FaTruckLoading/>} key={12}
                                           onClick={() => history.push("/dashboard/items/4")}
                                           title={'Quru, dəniz və hava nəqliyyatı növləri vasitəsilə yükün daşınması barədə məlumatlar'}>
                                    Quru, dəniz və hava nəqliyyatı növləri vasitəsilə yükün daşınması barədə məlumatlar
                                </Menu.Item>
                            </SubMenu>
                        </SubMenu>
                        {
                            user && user.user_group === 4 ? (
                                <SubMenu key={13} icon={<SettingOutlined/>} title={"Tənzimləmələr"}>
                                    <Menu.Item title={'Təşkilatlar'} icon={<CgOrganisation/>}
                                               onClick={() => history.push("/dashboard/organizations")}
                                               key={17}>
                                        Təşkilatlar
                                    </Menu.Item>
                                    <Menu.Item icon={<UserOutlined/>} onClick={() => history.push("/dashboard/users")}
                                               title={'İstifadəçilər'} key={16}>
                                        İstifadəçilər
                                    </Menu.Item>
                                    <Menu.Item title={'Ölçü vahidləri'} icon={<FaWeightHanging/>}
                                               onClick={() => history.push("/dashboard/weights")}
                                               key={19}>Ölçü
                                        vahidləri</Menu.Item>
                                    <Menu.Item icon={<DatabaseOutlined/>} title={'Nomenklatura'}
                                               onClick={() => history.push("/dashboard/settings/nomenclature")}
                                               key={14}>Nomenklatura</Menu.Item>
                                    <Menu.Item title={'Nomenklatura Qrupları'} icon={<VscGroupByRefType/>}
                                               onClick={() => history.push("/dashboard/groups")}
                                               key={100}>Nomenklatura Qrupları</Menu.Item>
                                    <Menu.Item title={'Təyinatlar'} icon={<AppstoreOutlined/>}
                                               onClick={() => history.push("/dashboard/assignments")}
                                               key={18}>Təyinatlar</Menu.Item>
                                    <Menu.Item title={'Ümumi tənzimləmələr'} icon={<FormOutlined/>}
                                               onClick={() => history.push("/dashboard/settings")}
                                               key={15}>Ümumi tənzimləmələr</Menu.Item>
                                    <Menu.Item title={'APİ Açarlar'} icon={<AiFillApi/>}
                                               onClick={() => history.push("/dashboard/api")}
                                               key={20}>API Açarlar</Menu.Item>
                                </SubMenu>
                            ) : <></>
                        }
                        <Menu.Item icon={<VideoCameraOutlined />} onClick={() => {
                            setVideoTutorialVisible(!videoTutorialVisible);
                        }}>
                            Video təlimata bax
                        </Menu.Item>
                    </Menu>
                </Sider>
                <LayoutAnt>
                    <Header className="header" style={{paddingLeft: '0', paddingRight: "2%", display: 'flex',}}>
                        <Row style={{width: "100%"}}>
                            <Col span={12}>
                                <p style={{color: "white", fontSize: 18, fontWeight: 'bold', paddingLeft: '25px'}}>Smeta
                                    Normativ Qiymətləndirmə İnformasiya Sistemi</p>
                            </Col>
                            <Col span={12} style={{textAlign: "right"}}>
                                <UserContextProvider>
                                    <ProfileButton/>
                                </UserContextProvider>
                            </Col>
                        </Row>
                    </Header>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: "24px 24px 0px 24px",
                            margin: 0,
                            minHeight: 280,
                            overflowY: "scroll",

                        }}
                    >
                        <Route exact path={"/dashboard/"} component={Index}/>
                        <Route exact path={"/dashboard/users"} component={Users}/>
                        <Route exact path={"/dashboard/weights"} component={Weight}/>
                        <Route exact path={"/dashboard/organizations"} component={Organizations}/>
                        <Route exact path={"/dashboard/api"} component={ApiKey}/>
                        <Route exact path={"/dashboard/settings"} component={AllSettings}/>
                        <Route exact path={"/dashboard/email"} component={EmailNotifications}/>
                        <Route exact path={"/dashboard/documents"} component={Documents}/>
                        <Route exact path={"/dashboard/verify"} component={Verify}/>
                        <Route exact path={"/dashboard/audit"} component={Audit}/>
                        <Route exact path={"/dashboard/assignments"} component={Assignments}/>
                        <Route exact path={"/dashboard/items/:id"} component={Items}/>
                        <Route exact path={"/dashboard/item/:id"} component={Item}/>
                        <Route exact path={"/dashboard/reports"} component={Reports}/>
                        <Route exact path={"/dashboard/my-reports"} component={MyOperations}/>
                        <Route exact path={"/dashboard/organization-reports"} component={OrgOperations}/>
                        <Route exact path={"/dashboard/groups"} component={Category}/>

                        <Route exact path={"/dashboard/settings/nomenclature"} component={Nomenclature}/>


                    </Content>
                    <Footer style={{
                        textAlign: 'center',
                        padding: "6px 0",
                        fontSize: "12px"
                    }}>© «Elektron Hökumətin İnkişafı Mərkəzi» PHŞ - {new Date().getFullYear()} </Footer>
                </LayoutAnt>
            </LayoutAnt>
            <Modal title={'Video Təlimat'} width={'1024px'} style={{height: '100vh'}} onCancel={() => setVideoTutorialVisible(false)} visible={videoTutorialVisible}>
                {videoFrame}
            </Modal>
        </div>
    )
}
