import {useHistory} from "react-router-dom";
import {useState} from "react";
import PageHeading from "../../components/PageHeading";
import GenericFormModal from "../../forms/genericFormModal";
import {
    acceptAuditTask, downloadAttachment,
    getAuditTask,
    getAuditTasks,
    rejectAuditTask
} from "../../adapters/api.adapter";
import {Button, DatePicker, Typography, Form, Input, message, Upload} from "antd";
import ExtendedTable from "../../components/ExtendedTable";
import {CheckOutlined, CloseOutlined, FileSearchOutlined, HistoryOutlined} from "@ant-design/icons";
import moment from "moment";
import ExpandedRow from "../../components/ExpandedRow";
import {saveAs} from 'file-saver';

const {TextArea} = Input;
const {Text} = Typography;


export default function Audit() {
    const history = useHistory()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingRecordId, setEditingRecordId] = useState();
    const [ignoreRecord, setIgnoreRecord] = useState();
    const [isUpdated, setIsUpdated] = useState();
    const [isLoading, setLoading] = useState();

    const [status, setStatus] = useState(0);

    const [attachments, setAttachments] = useState([]);

    const [assignmentType, setAssignmentType] = useState();

    const [fileList, setFileList] = useState([])
    const downloadFile = (id, name) => {
        setLoading(true)
        downloadAttachment(id).then(({data, type}) => {
            const file = new File([data], name, {type: type});
            return saveAs(file);
        }).catch(e => {
            message.error("Xəta!")
        }).finally(() => setLoading(false));
    }

    return (
        <div>
            <PageHeading title={"Müşahidəçi Qrupu Sənədləri"} path={"Panel/Əməliyyatlar/Müşahidəçi Qrupu Sənədləri"}/>
            <GenericFormModal
                ignoreRecord={ignoreRecord} recordId={editingRecordId}
                title={status === 0 ? "İmtina et" : "Təsdiq et"}
                update={() => setIsUpdated(!isUpdated)}
                setIsModalVisible={setIsModalVisible}
                isModalVisible={isModalVisible}
                extraLoading={isLoading}
                dataEntrance={(record) => {
                    delete record.description;

                    setAttachments(record.attachments)
                    setAssignmentType(record.assignment_type);
                    record.pricing_date = moment(record.pricing_date)
                    return record
                }}
                dataManipulation={(record) => {
                    const formData = new FormData();
                    for (const name in record) {
                        if(name !== "attachments" || !record[name])
                            formData.append(name, record[name]);
                    }

                    for (let i = 0; i < fileList.length; i++) {
                        formData.append(`attachments[]`, fileList[i])
                    }

                    formData.append("id", record.task_id)
                    return formData;
                }}
                ignoreRecordId
                mainFunctions={{
                    get: getAuditTask,
                    update: status === 0 ? rejectAuditTask : acceptAuditTask,
                    create: status === 0 ? rejectAuditTask : acceptAuditTask
                }}
            >

                <Form.Item
                    name={"task_id"}
                    label={"Task kodu"}
                >
                    <Input disabled/>
                </Form.Item>
                <Form.Item
                    name={"lastPrice"}
                    label={"Əvvəlki qiyməti"}
                >
                    <Input disabled/>
                </Form.Item>
                {
                    assignmentType !== 4 && (
                        <Form.Item
                            name={"price"}
                            label={"Daxil olunan qiymət"}
                        >
                            <Input disabled/>
                        </Form.Item>
                    )
                }
                {
                    assignmentType === 4 && (
                        <>
                            <Form.Item
                                name={"price"}
                                label={"Maşının daxil olunan qiyməti"}
                            >
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item
                                name={"lastSalaryPrice"}
                                label={"Maşinistin əvvəlki əmək haqqı"}
                            >
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item
                                name={"salary_price"}
                                label={"Maşinistin daxil olunan əmək haqqı"}
                            >
                                <Input disabled/>
                            </Form.Item>
                        </>
                    )
                }

                {/*{*/}
                {/*    assignmentType === 4 && (*/}
                {/*        <Form.Item*/}
                {/*            name={"currentPrice"}*/}
                {/*            label={"İndiki qiymət"}*/}
                {/*        >*/}
                {/*            <Input disabled/>*/}
                {/*        </Form.Item>*/}
                {/*    )*/}
                {/*}*/}
                <Form.Item
                    name={"description"}
                    label={"Qeyd"}
                    rules={status !== 1 ? [
                        {
                            required: true,
                            message: "Qeyd daxil edin",
                        }
                    ] : null}
                >
                    <TextArea
                        placeholder="Qeyd"
                        autoSize={{minRows: 3, maxRows: 5}}
                    />
                </Form.Item>
                <Form.Item
                    name={"pricing_date"}
                    label={"Qiymətləndirmə tarixi"}
                >
                    <DatePicker style={{width: "100%"}} disabled placeholder={"Vaxt seçin"} showTime
                                format={"YYYY-DD-MM HH:mm"}/>
                </Form.Item>
                {
                    attachments.length !== 0 && (<><Text style={{fontWeight: "bold"}}>Qoşmalar</Text>
                        <div>
                            {attachments.map(k => <div key={k.id}><a onClick={() => downloadFile(k.id, k.name)}
                                                                     style={{padding: 0, margin: 0}}>{k.name} - {k.uploaded_by} tərəfindən yükləndi</a>
                            </div>)}
                        </div>
                    </>)
                }
                <Form.Item
                    name="attachments"
                    label="Qoşma əlavə edin"
                    getValueFromEvent={({file}) => file.originFileObj}
                >
                    <Upload
                        maxCount={1}
                        fileList={fileList}
                        beforeUpload={(file, fileList) => {
                            setFileList(fileList);
                        }}
                        onRemove={(e) => {
                            setFileList(fileList.filter(k => k.uid !== e.uid))
                        }}
                        multiple
                    >
                        <Button type={"upload"}>Yüklə</Button>`
                    </Upload>
                </Form.Item>
            </GenericFormModal>
            <ExtendedTable
                history={history}
                fetchFunction={getAuditTasks}
                update={isUpdated}
                actions={{
                    custom: [
                        {
                            icon: <CheckOutlined style={{color: "limegreen"}}/>,
                            isShown: (text, record) => !record.isRejected && !record.isApproved,
                            onClick: (id) => {
                                setStatus(1)
                                setEditingRecordId(id);
                                setIgnoreRecord(false);
                                setIsModalVisible(true);
                            }
                        },
                        {
                            icon: <CloseOutlined style={{color: "orangered"}}/>,
                            isShown: (text, record) => !record.isRejected && !record.isApproved,
                            onClick: (id) => {
                                setStatus(0)
                                setEditingRecordId(id);
                                setIgnoreRecord(false);
                                setIsModalVisible(true);
                            }
                        },
                        {
                            icon: <FileSearchOutlined/>,
                            onClick: (id, record) => {
                                const url = new URL(window.location);
                                const win = window.open(url.origin + "/dashboard/item/" + record.item_id, "_blank");
                                win.focus();
                            }
                        }
                    ]
                }}
                expandable={{
                    expandedRowRender: record => <ExpandedRow record={record} />,
                    expandIcon: ({expanded, onExpand, record}) =>
                        <Button size={"small"} onClick={e => onExpand(record, e)}><HistoryOutlined/></Button>,
                    rowExpandable: record => record.progressLogs.length >= 0
                }}
            />
        </div>
    )
}
