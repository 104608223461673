import {Col, Row, Skeleton, Typography} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";

const {Text} = Typography;

export default function PriceComp({loading, p1 = 0, p2 = 0, p3 = 0, riskPercent = 10}) {
    const tStyles = {fontSize: "30px", fontWeight: "bold", textAlign: "center"};
    const bStyles = {textAlign: "center"};

    return (
        <div style={{padding: "10px", border: "1px solid #ccc", backgroundColor: "white"}}>
            {loading ? <Skeleton/> :
                <Row style={{width: "100%", margin: "auto"}}>
                    <Col span={6}>
                        <div style={bStyles}>
                            <Text style={tStyles}>{p1}</Text>
                        </div>
                        <div style={bStyles}>
                            <Text style={{fontSize: "10px", color: "#ccc"}}>Qiymət dəyişimi sayı</Text>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div style={bStyles}>
                            <Text style={{...tStyles, color: Math.abs(p2) > riskPercent ? "orangered" : "limegreen"}}>{
                                p2 > 0 ? <ArrowUpOutlined/> : <ArrowDownOutlined/>
                            }{p2.toFixed(3)}%</Text>
                        </div>
                        <div style={bStyles}>
                            <Text style={{fontSize: "10px", color: "#ccc"}}>Qiymət fərqi</Text>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div style={bStyles}>
                            <Text style={tStyles}>₼{p3}</Text>
                        </div>
                        <div style={bStyles}>
                            <Text style={{fontSize: "10px", color: "#ccc"}}>Cari qiymət</Text>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div style={bStyles}>
                            <Text style={tStyles}>{riskPercent}%</Text>
                        </div>
                        <div style={bStyles}>
                            <Text style={{fontSize: "10px", color: "#ccc"}}>Riskli qiymət faizi</Text>
                        </div>
                    </Col>
                </Row>
            }
        </div>
    )
}