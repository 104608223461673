import PageHeading from "../../components/PageHeading";
import {useHistory} from "react-router-dom";
import ExtendedTable from "../../components/ExtendedTable";
import {
    createMeasurementUnit,
    getMeasurementUnit,
    getMeasurementUnits, updateMeasurementUnit,
} from "../../adapters/api.adapter";
import {Button, Form, Input, Radio} from "antd";
import {useState} from "react";
import {PlusOutlined} from "@ant-design/icons";
import GenericFormModal from "../../forms/genericFormModal";

export default function Weights() {
    const history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingRecordId, setEditingRecordId] = useState();
    const [ignoreRecord, setIgnoreRecord] = useState();
    const [isUpdated, setIsUpdated] = useState();

    return (
        <div>
            <PageHeading title={"Ölçü vahidləri"} path={"Panel/Tənzimləmələr/Ölçü Vahidləri"}>
                <Button onClick={() => setIsModalVisible(true)} icon={<PlusOutlined/>} type={"primary"}>
                    Əlavə et
                </Button>
            </PageHeading>

            <GenericFormModal
                ignoreRecord={ignoreRecord}
                recordId={editingRecordId}
                update={() => setIsUpdated(!isUpdated)}
                setIsModalVisible={setIsModalVisible}
                isModalVisible={isModalVisible}
                mainFunctions={{get: getMeasurementUnit, update: updateMeasurementUnit, create: createMeasurementUnit}}
            >
                <Form.Item
                    name={"name"}
                    label={"Adı"}
                    rules={[
                        {
                            required: true,
                            message: "Ölçü vahidinin adı daxil edilməlidir"
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name={"unit"}
                    label={"Vahid"}
                    rules={[
                        {
                            required: true,
                            message: "Vahidinin adı daxil edilməlidir"
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name={"symbol"}
                    label={"Simvol"}
                    rules={[
                        {
                            required: true,
                            message: "Simvol daxil edilməlidir"
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                {
                    (() => {
                        if (editingRecordId && !ignoreRecord) {
                            return (
                                <Form.Item
                                    name={"is_active"}
                                    label={"Status"}
                                >
                                    <Radio.Group>
                                        <Radio value={1}>Aktiv</Radio>
                                        <Radio value={0}>Deaktiv</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            )
                        }
                    })()
                }

            </GenericFormModal>

            <ExtendedTable
                history={history}
                update={isUpdated}
                fetchFunction={getMeasurementUnits}
                actions={{
                    edit: (id) => {
                        setEditingRecordId(id);
                        setIgnoreRecord(false);
                        setIsModalVisible(true);
                    }
                }}
            />
        </div>
    )
}