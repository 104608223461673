import {useEffect, useState} from "react";
import {getUserDetails} from "../adapters/api.adapter";
import {Skeleton} from "antd";

const roles = ["Monitorinq Əməliyyatçısı", "Monitorinq Təsdiqləyici", "Müşahidə Qrupu", "Sistem Sahibi"];


export default function PopoverUserToolTip({id}) {

    const [user, setUser] = useState();

    useEffect(() => {
        if (!id) return setUser({organisationName: "Sistem", role: 10, email: "Smeta Normativ Qiymətqoyma Sistemi"})
        getUserDetails(id).then(setUser)
    }, [id])

    if (!user) {
        return (
            <div>
                <Skeleton/>
            </div>
        )
    }

    return (
        <div>
            {user.role === 10 ? <></> : (
                <div><p style={{lineHeight: "5px"}}>{user.organisationName}</p>
                    <p style={{lineHeight: "5px"}}>{user.role === 10 ? "Sistem" : roles[user.role - 1]}</p></div>
            )}
            <a href={user.email === "sistem" ? "" : `mailto:${user.email}`} style={{lineHeight: "5px"}}>{user.email}</a>
        </div>
    )
}