import axios_api_instance from "../config/api.config";


// User Route

export const getSessionUser = async () => {
    try {
        const result = await axios_api_instance.get("/me");
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const createUser = async (data) => {
    try {
        const result = await axios_api_instance.post("/users", data);
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getAllUsers = async (params) => {
    const result = await axios_api_instance.get("/users", {params: params});
    return result.data;
}

export const updateUser = async (id, data) => {
    try {
        const result = await axios_api_instance.put("/users", {...data, id});
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getUser = async (id) => {
    try {
        const result = await axios_api_instance.get(`/users/single?id=${id}`);
        return result.data
    } catch (e) {
        throw e.response;
    }
}

export const getGroups = async () => {
    const result = await axios_api_instance.get("/users/groups");
    return result.data;
}


// Measurement Units

export const getMeasurementUnits = async (params) => {
    const result = await axios_api_instance.get("/measurement-units", {params});
    return result.data;
}

export const getMeasurementUnit = async (id) => {
    const result = await axios_api_instance.get(`/measurement-units/single?id=${id}`);
    return result.data;
}

export const updateMeasurementUnit = async (id, data) => {
    const result = await axios_api_instance.put("/measurement-units", {...data, id});
    return result.data;
}

export const createMeasurementUnit = async (data) => {
    try {
        const result = await axios_api_instance.post("/measurement-units", data)
        return result.data;
    } catch (e) {
        throw e.response
    }
}

// Load classes

export const createLoadClass = async (data) => {
    try {
        const result = await axios_api_instance.post("/load-classifications", data);
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getAllLoadClasses = async (params) => {
    try {
        const result = await axios_api_instance.get("/load-classifications", {params});
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getLoadClass = async (id) => {
    try {
        const result = await axios_api_instance.get(`/load-classifications/single?id=${id}`);
        return result.data
    } catch (e) {
        throw e.response;
    }
}

export const updateLoadClass = async (id, data) => {
    try {
        const result = await axios_api_instance.put("/load-classifications", {...data, id});
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

// reports
export const getReports = async () => {
    try {
        const result = await axios_api_instance.get("/reports/dashboard")
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

// organizations
export const getOrganizations = async (params) => {
    const result = await axios_api_instance.get("/organizations", {params});
    return result.data;
}

export const createOrganization = async (data) => {
    try {
        const result = await axios_api_instance.post("/organizations", data);
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const updateOrganization = async (id, data) => {
    try {
        const result = await axios_api_instance.put("/organizations", {...data, id});
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getOrganizationsSelect = async () => {
    const result = await axios_api_instance.get("/organizations/select");
    return result.data;
}

export const getOrganization = async (id) => {
    const result = await axios_api_instance.get(`/organizations/single?id=${id}`);
    return result.data;
}

// Api Keys
export const getApiKey = async (id) => {
    const result = await axios_api_instance.get(`/api-tokens/single?id=${id}`);
    return result.data;
}

export const getApiKeys = async (params) => {
    const result = await axios_api_instance.get(`/api-tokens`, {params});
    return result.data;
}

export const createApiKey = async (data) => {
    try {
        const result = await axios_api_instance.post("/api-tokens/generate", data)
        return result.data
    } catch (e) {
        throw e.response;
    }
}

export const updateApiKey = async (id, data) => {
    try {
        const result = await axios_api_instance.put("/api-tokens", {...data, id})
        return result.data
    } catch (e) {
        throw e.response;
    }
}

export const getOperatorTasks = async (params) => {
    try {
        const result = await axios_api_instance.get("/tasks/operator", {params});
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const putOperatorTask = async (data) => {
    try {
        const result = await axios_api_instance.post("/tasks/operator-submit", data, {headers: {"Content-Type": "multipart/form-data"}});
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const operateVerifierTask = (status) => {
    if (status === 0) {
        return async (data) => {
            try {
                const result = await axios_api_instance.post("/tasks/verifier-submit", data, {headers: {"Content-Type": "multipart/form-data"}});
                return result.data;
            } catch (e) {
                throw e.response;
            }
        }
    }
    if (status === 1) {
        return async (data) => {
            try {
                const result = await axios_api_instance.post("/tasks/verifier-submit", data, {headers: {"Content-Type": "multipart/form-data"}});
                return result.data;
            } catch (e) {
                throw e.response;
            }
        }
    }
}

export const getOperatorTask = async (id) => {
    try {
        const result = await axios_api_instance.get(`/tasks/single-operator-document?id=${id}`);
        return result.data;
    } catch (e) {
        throw e.response;
    }
}


export const getVerifierTasks = async (params) => {
    try {
        const result = await axios_api_instance.get("/tasks/verifier", {params});
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getVerifierTask = async (id) => {
    try {
        const result = await axios_api_instance.get(`/tasks/single-verifier-document?id=${id}`);
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getAuditTasks = async (params) => {
    try {
        const result = await axios_api_instance.get(`/tasks/observer`, {params});
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getAuditTask = async (id) => {
    try {
        const result = await axios_api_instance.get(`/tasks/single-observer-document?id=${id}`);
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const rejectAuditTask = async (data) => {
    try {
        const result = await axios_api_instance.post(`/tasks/observer-reject`, data);
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getUserDetails = async (id) => {
    try {
        const result = await axios_api_instance.get(`/users/details?id=${id}`);
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getTaskProductsSearch = async (search) => {
    try {
        const result = await axios_api_instance.get(`/form-data/get-assignment-products?name=${search}`);
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getAssignmentsRegionSearch = async (params) => {
    try {
        const result = await axios_api_instance.get(`/form-data/get-products`, {params});
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getRegionsSearch = async (search) => {
    try {
        const result = await axios_api_instance.get(`/form-data/get-assignment-products?name=${search}`);
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const operatorCreateTask = async (data) => {
    try {
        const result = await axios_api_instance.post("/tasks/operator-create", {...data, status: 0});
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getAssignments = async (params) => {
    try {
        const result = await axios_api_instance.get(`/assignments`, {params});
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getUsersForAssignments = async (params) => {
    try {
        const result = await axios_api_instance.get("/users/tree", {params});
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const createNewAssignment = async (data) => {
    try {
        const result = await axios_api_instance.post("/assignments", data);
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getItemsBasedOnModule = (moduleId) => {
    return async (params) => {
        try {
            const result = await axios_api_instance.get(`/items?module_type=${moduleId}`, {params});
            return result.data;
        } catch (e) {
            throw e.response;
        }
    }
}

export const getEmailNotifications = async (params) => {
    try {
        const result = await axios_api_instance.get("/email", {params});
        return result.data
    } catch (e) {
        throw e.response;
    }
}

export const sendPendingEmails = async () => {
    try {
        const result = await axios_api_instance.post("/email/send");
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const generateEmails = async () => {
    try {
        const result = await axios_api_instance.post("/email/generate");
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getItemSingle = async (id, params) => {
    try {
        const result = await axios_api_instance.get(`/items/single?id=${id}`, {params});
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getEmailTemplates = async () => {
    try {
        const result = await axios_api_instance.get(`/email/templates`);
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getEmailTemplate = async (id) => {
    try {
        const result = await axios_api_instance.get(`/email/template?key=${id}`);
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const updateEmailTemplate = async (id, data) => {
    try {
        const result = await axios_api_instance.post(`/email/template/update`, {...data, key: id});
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

// /tasks/verified-tasks

export const getVerifiedTasks = async (params) => {
    try {
        const result = await axios_api_instance.get(`/tasks/verified-tasks`, {params});
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const downloadSingleItemReport = async (id, params) => {
    try {
        const result = await axios_api_instance.get(`/reports/export-selected-item?id=${id}`, {
            responseType: "arraybuffer",
            params
        });
        return {data: result.data, type: result.headers['content-type']};
    } catch (e) {
        throw e.response;
    }
}

export const downloadReport = async (params) => {
    try {
        const result = await axios_api_instance.get("/reports/exportToExcel", {responseType: "arraybuffer", params});
        return {data: result.data, type: result.headers['content-type']};
    } catch (e) {
        throw e.response;
    }
}

export const downloadReportFromModule = (id) => {
    return async (params) => {
        try {
            const result = await axios_api_instance.get(`/reports/export-selected-module?module_type=${id}`, {
                responseType: "arraybuffer",
                params
            });
            return {data: result.data, type: result.headers['content-type']};
        } catch (e) {
            throw e.response;
        }
    }
}

export const updateSettings = async (key, data) => {
    try {
        const result = await axios_api_instance.post("/settings", {key, settings: data});
        return result.data;
    } catch (e) {
        throw e.response.data;
    }
}

export const getSettings = async (key) => {
    try {
        const result = await axios_api_instance.get(`/settings?key=${key}`);
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const toggleAssignment = async (id) => {
    try {
        const result = await axios_api_instance.post(`/assignments/toggle?id=${id}`);
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const deleteEmail = async (id) => {
    try {
        const result = await axios_api_instance.put(`/email?id=${id}`);
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getItemsSettingsBasedOnModule = (moduleId) => {
    return async (params) => {
        try {
            const result = await axios_api_instance.get(`/items/settings?module_type=${moduleId}`, {params});
            return result.data;
        } catch (e) {
            throw e.response;
        }
    }
}

export const getSingleNomenc = async (id) => {
    try {
        const result = await axios_api_instance.get(`/items/settings/single?id=${id}`);
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const updateSingleNomenc = async (id, data) => {
    try {
        const result = await axios_api_instance.put("/items/settings", {id, ...data});
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getAssignmentTypes = async () => {
    try {
        const result = await axios_api_instance.get("/form-data/assignment-types");
        return result.data;
    } catch (e) {
        throw e.response;
    }

}


export const getNomencGroups = async () => {
    try {
        const result = await axios_api_instance.get("/form-data/get-item-groups");
        return result.data;
    } catch (e) {
        throw e.response;
    }

}

export const getMeasurementUnitsSelect = async () => {
    try {
        const result = await axios_api_instance.get("/measurement-units/select");
        return result.data;
    } catch (e) {
        throw e.response;
    }

}

export const createNomenc = async (data) => {
    try {
        const result = await axios_api_instance.post("/items/settings", data);
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const acceptAuditTask = async (data) => {
    try {
        const result = await axios_api_instance.post("/tasks/observer-approve", data);
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getMyOperations = async (params) => {
    try {
        const result = await axios_api_instance.get("/reports/documents", {params});
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getOrgOperations = async (params) => {
    try {
        const result = await axios_api_instance.get("/reports/organisation-documents", {params});
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getSingleAssignmentById = async (id) => {
    try {
        const result = await axios_api_instance.get(`/assignments/single?id=${id}`);
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const updateSingleAssignment = async (id, data) => {
    try {
        const result = await axios_api_instance.put(`/assignments`, {...data, id});
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const downloadAttachment = async (id) => {
    try {
        const result = await axios_api_instance.get("/tasks/download-attachment", {
            responseType: "arraybuffer",
            params: {id}
        })
        return {data: result.data, type: result.headers['content-type']};
    } catch (e) {
        throw e.response;
    }
}

export const getNomenclatureGroups = (id) => {
    return async function (params) {
        try {
            const result = await axios_api_instance.get("/nomenclature_groups", {
                params: {
                    ...params,
                    assignmentType: id
                }
            })
            return result.data
        } catch (e) {
            throw e.response;
        }
    }
}

export const getSingleNomencGroup = async (id) => {
    try {
        const result = await axios_api_instance.get(`/nomenclature_groups/single?id=${id}`)
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const createNomencGroup = async (data) => {
    try {
        const result = await axios_api_instance.post("/nomenclature_groups", {...data, is_active: true})
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const updateNomencGroup = async (id, data) => {
    try {
        const result = await axios_api_instance.put("/nomenclature_groups", {...data, id})
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const getNomencGroupSelect = async (type = 1) => {
    try {
        const result = await axios_api_instance.get(`/nomenclature_groups/select?assignmentType=${type}`)
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const excelImport = async (id, file) => {
    // const data = {
    //     excel_file: file,
    //     import_type: id
    // }

    const data = new FormData();
    data.append('import_type', id);
    data.append('excel_file', file);

    try {
        const result = await axios_api_instance.post("/import", data)
        return result.data;
    } catch (e) {
        throw e.response;
    }
}

export const verifiedAll = async (tasks) => {
    try {
        const result = await axios_api_instance.post('/tasks/verifier-submit-multi', {tasks})
        return result.data;
    } catch (e) {
        throw e.response;
    }
}