import PageHeading from "../../components/PageHeading";
import {Button, Form, Input, Radio, Select} from "antd";
import {PlusOutlined, QuestionCircleOutlined} from '@ant-design/icons';

import {useState} from "react";
import {createApiKey, getApiKey, getApiKeys, updateApiKey} from "../../adapters/api.adapter";
import ExtendedTable from "../../components/ExtendedTable";
import {useHistory} from "react-router-dom";
import GenericFormModal from "../../forms/genericFormModal";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'


const test = `
### **Api çıxışlar:**
_İdentifikasiya kodu ilə:_

\`https://snqis.gov.az/api/v1-public/price?api-token={your-token}&module={code}&id={id}\`
_Sahə kodu ilə:_
\`https://snqis.gov.az/api/v1-public/price?api-token={your-token}&module={code}&field-code={field-code}\`
### Nomenklatura nömrələri: [1,2,3,4]
- #### 1 Tikintidə tətbiq  edilən  materiallar,  məmulatlar, konstruksiyalar, mebel - inventar və avadanlıqlar üzrə
- #### 2 Tikinti maşın - mexanizmlərin və avtonəqliyyat vasitələrinin istismar xərcləri barədə məlumatlar
- #### 3 Tikintidə əməyin ödənilməsinə aid məlumatlar
- #### 4 Quru, dəniz və hava  nəqliyyatı növləri vasitəsilə yükün daşınması
### Qayıdan cavablar:
- #### 1000 - Nomenklatura düzgün doldurulmayıb
- #### 1001 - Sahə kodu vəya İdentifikasiya kodu düzgün deyil
- #### 1002 - Axtardığınız nomenklatura tapılmadı. Zəhmət olmasa sistem sahibi ilə əlaqə saxlayın.
### JavaScript dilində nümunə sorğu:
\`\`\`javascript
var requestOptions = {
    method: 'GET',
    redirect: 'follow'
};
fetch("https://snqis.gov.az/api/v1-public/price?api-token={your-token}&id={id}&module=3", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result));
\`\`\`

`

export default function ApiKey() {
    const history = useHistory()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingRecordId, setEditingRecordId] = useState();
    const [ignoreRecord, setIgnoreRecord] = useState();
    const [isUpdated, setIsUpdated] = useState();

    return (
        <div>
            <PageHeading title={"APİ Açarlar"} path={"Panel/Tənzimləmələr/APİ Açarlar"}>
                <div>
                    <Button style={{marginRight: "10px"}} icon={<QuestionCircleOutlined />} onClick={() => {
                        const url = new URL(window.location);
                        const win = window.open(url.origin+"/api-test");
                        win.focus();
                    }}>
                        Test Mühitinə Keçid
                    </Button>
                    <Button icon={<PlusOutlined/>} type={"primary"} onClick={() => {
                        setIsModalVisible(true);
                        setIgnoreRecord(true)
                    }}>
                        Əlavə et
                    </Button>
                </div>
            </PageHeading>
            <GenericFormModal
                ignoreRecord={ignoreRecord} recordId={editingRecordId}
                setIsModalVisible={setIsModalVisible}
                isModalVisible={isModalVisible}
                update={() => setIsUpdated(!isUpdated)}
                mainFunctions={{
                    get: getApiKey,
                    update: updateApiKey,
                    create: createApiKey,
                }}
            >
                <Form.Item
                    name={"name"}
                    label={"Adı"}
                    rules={[
                        {
                            required: true,
                            message: "Ad daxil edin!"
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name={"allowed_origin"}
                    label={"Mənşə"}
                    rules={[
                        {
                            required: true,
                            message: "Mənşə daxil edin!"
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name={"description"}
                    label={"Açıqlama"}
                >
                    <Input/>
                </Form.Item>
                {
                    (() => {
                        if (editingRecordId && !ignoreRecord) {
                            return (
                                <Form.Item
                                    name={"is_active"}
                                    label={"Status"}
                                >
                                    <Radio.Group>
                                        <Radio value={0}>Deaktiv</Radio>
                                        <Radio value={1}>Aktiv</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            )
                        }
                    })()
                }
            </GenericFormModal>
            <ExtendedTable
                update={isUpdated}
                history={history}
                fetchFunction={getApiKeys}
                actions={{
                edit: (id) => {
                    setEditingRecordId(id);
                    setIgnoreRecord(false);
                    setIsModalVisible(true);
                }
            }}/>
            <div style={{width: "60%", backgroundColor: "white", padding: "13px"}}>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {test.trim()}
                </ReactMarkdown>
            </div>
        </div>
    )
}