import { SaveOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, message } from "antd";
import { saveAs } from "file-saver";
import { useState } from "react";

export default function ExportButton({ get, dropdown = [] }) {
  const [loading, setLoading] = useState();

  const menu = (
    <Menu>
      {dropdown.map((l, k) => (
        <Menu.Item k={k} onClick={() => console.log(l.params)} title={l.text}>
          {l.text}
        </Menu.Item>
      ))}
    </Menu>
  );

  const downloadFile = (params) => {
    setLoading(true);
    get(params)
      .then(({ data, type }) => {
        const file = new File([data], "hesabat.xlsx", { type: type });
        return saveAs(file);
      })
      .catch((e) => {
        message.error("Xəta!");
      })
      .finally(() => setLoading(false));
  };

  if (dropdown.length > 0) {
    return (
      <Dropdown overlay={menu}>
        <Button
          style={{ width: "100%" }}
          loading={loading}
          icon={<SaveOutlined />}
        >
          Excel hesabat çıxar
        </Button>
      </Dropdown>
    );
  }

  return (
    <Button
      style={{ width: "100%" }}
      onClick={() => downloadFile()}
      loading={loading}
      icon={<SaveOutlined />}
    >
      Excel hesabat çıxar
    </Button>
  );
}
