import {useHistory} from "react-router-dom";
import {Col, List, Row, Button} from 'antd';
import ExtendedTable from "../../components/ExtendedTable";
import PageHeading from "../../components/PageHeading";
import {downloadReport, getVerifiedTasks} from "../../adapters/api.adapter";
import ReportFilters from "../../components/ReportFilters";
import {CheckOutlined, CloseOutlined, FileSearchOutlined, HistoryOutlined} from "@ant-design/icons";
import ExpandedRow from "../../components/ExpandedRow";


export default function Reports() {
    const history = useHistory()

    return (
        <div>
            <PageHeading title={"Təsdiqlənmiş Qiymətlər"} path={"Panel/Hesabatlar/Təsdiqlənmiş Qiymətlər"}>
                <ReportFilters get={downloadReport} dropDown={[
                    {
                        params: {module_type: 1},
                        text: "Tikintidə istifadə edilən materiallar üzrə",
                    },
                    {
                        params: {module_type: 2},
                        text: "Tikintidə əməyin ödənilməsi üzrə"
                    },
                    {
                        params: {module_type: 3},
                        text: "Mexanizm istismar xərcləri üzrə"
                    },
                    {
                        params: {module_type: 4},
                        text: "Daşınma üzrə"
                    }
                ]} />
            </PageHeading>
            <ExtendedTable
                history={history}
                fetchFunction={getVerifiedTasks}
                actions={{
                    custom: [
                        {
                            icon: <FileSearchOutlined/>,
                            onClick: (id, record) => {
                                const url = new URL(window.location);
                                const win = window.open(url.origin+"/dashboard/item/"+record.item_id, "_blank");
                                win.focus();
                            }
                        }
                    ]
                }}
                expandable={{
                    expandedRowRender: record => <ExpandedRow record={record} />,
                    expandIcon: ({expanded, onExpand, record}) =>
                        <Button size={"small"} onClick={e => onExpand(record, e)}><HistoryOutlined/></Button>,
                    rowExpandable: record => record.progressLogs.length >= 0
                }}
            />
        </div>
    )
}
