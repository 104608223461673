import PageHeading from "../../components/PageHeading";
import {
    Button,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Select,
    TreeSelect,
    Tag,
    Typography,
    AutoComplete, Tooltip, Radio, Checkbox
} from "antd";
import ExtendedTable from "../../components/ExtendedTable";
import {useHistory} from "react-router-dom";
import {
    createNewAssignment,
    getAssignments,
    getAssignmentsRegionSearch, getNomencGroupSelect,
    getOrganizationsSelect, getSingleAssignmentById, getUsersForAssignments, toggleAssignment, updateSingleAssignment
} from "../../adapters/api.adapter";
import {useEffect, useState} from "react";
import ErrorHolder from "../../components/ErrorHolder";
import {SecurityScanOutlined, EditOutlined} from "@ant-design/icons";
import _ from "lodash";

const {Text} = Typography

const ASSIGNMENT_TYPES = [
    {
        key: 1,
        value: 1,
        label: "Tikintidə tətbiq  edilən  materiallar,  məmulatlar, konstruksiyalar, mebel - inventar və avadanlıqlar üzrə"
    },
    {
        key: 2,
        value: 2,
        label: "Tikinti maşın - mexanizmlərin və avtonəqliyyat vasitələrinin istismar xərcləri barədə məlumatlar"
    },
    {
        key: 3,
        value: 3,
        label: "Tikintidə əməyin ödənilməsinə aid məlumatlar"
    },
    {
        key: 4,
        value: 4,
        label: "Quru, dəniz və hava  nəqliyyatı növləri vasitəsilə yükün daşınması"
    }
]

const REGION_TYPES = [
    {
        key: 1,
        value: 1,
        label: "Ümumi"
    },
    {
        key: 2,
        value: 0,
        label: "Regional"
    }
]

const GLOBAL_REGION = [
    {
        key: 0,
        value: 0,
        label: "Ümumi"
    }
]


const REGIONS = [

    {
        key: 1,
        value: 1,
        label: "Bakı"
    },
    {
        key: 2,
        value: 2,
        label: "Naxçıvan"
    },
    {
        key: 3,
        value: 3,
        label: "Abşeron-Xızı"
    },
    {
        key: 4,
        value: 4,
        label: "Dağlıq Şirvan"
    },
    {
        key: 5,
        value: 5,
        label: "Gəncə-Daşkəsən"
    },
    {
        key: 6,
        value: 6,
        label: "Qarabağ iqtisadi"
    },
    {
        key: 7,
        value: 7,
        label: "Qazax-Tovuz"
    },
    {
        key: 8,
        value: 8,
        label: "Quba-Xaçmaz"
    },
    {
        key: 9,
        value: 9,
        label: "Lənkəran-Astara"
    },
    {
        key: 10,
        value: 10,
        label: "Mərkəzi Aran"
    },
    {
        key: 11,
        value: 11,
        label: "Mil-Muğan"
    },
    {
        key: 12,
        value: 12,
        label: "Şəki-Zaqatala"
    },
    {
        key: 13,
        value: 13,
        label: "Şərqi Zəngəzur"
    },
    {
        key: 14,
        value: 14,
        label: "Şirvan-Salyan"
    }

]


const shortendName = (s = "Dəmir-beton konstruksiyaların armaturlanması üçün prokat, dairəvi və dövri profilli, qaynar yayılmış və termomexaniki, termik möhkəmləndirilmiş, A-I sinifli, diametri 6 mm") => {
    if (s.length > 150) {
        return s.substring(0, 29) + "..." + s.substring(s.length - 30, s.length);
    }
    return s;
}


export default function Assignments({}) {
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();

    const assigmnts_all_for_form1 = Form.useWatch('assigmnts_all', form1)
    const assigmnts_all_for_form2 = Form.useWatch('assigmnts_all', form2)

    const history = useHistory();
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [itemSearch, setItemSearch] = useState([]);
    const [orgs, setOrgs] = useState();
    const [regions, setRegions] = useState();
    const [loading, setLoading] = useState(false);
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [searchUsers, setSearchUsers] = useState([]);
    const [formState, setFormState] = useState(0);
    const [isUpdated, setIsUpdated] = useState();
    const [error, setError] = useState();
    const [isEditingModalVisible, setIsEditingModalVisible] = useState(false);
    const [isRegionGlobal, setIsRegionGlobal] = useState(false);
    const [checked, setChecked] = useState(false)

    const [assignmentSelectType, setAssignmentSelectType] = useState();

    const [editingError, setEditingError] = useState();
    const [editingId, setEditingId] = useState();

    const [editingAudit, setEditingAudit] = useState([]);
    const [editingItems, setEditingItems] = useState([]);
    const [editingOrg, setEditingOrg] = useState([]);
    const [nomencGroups, setNomencGroups] = useState();
    const [assignmentType, setAssignmentType] = useState();
    const [editingOrgs, setEditingOrgs] = useState();

    const postStuff = async () => {

        setLoading(true);

        try {
            await form1.validateFields();

            try {
                const formResult = form1.getFieldsValue();
                formResult.is_global_region = formResult.is_global;
                formResult.assignment_observation_groups = formResult.assignment_observation_groups.map(k => ({
                    "is_organization": 0,
                    "morph_id": k
                }));
               if(formResult.assigmnts_all) {
                formResult.assigned_items = null
               }

                await createNewAssignment(formResult);
                setFormState(0)
                setItemSearch([]);
                setEditingItems([]);
                form1.resetFields();
                setLoading(false);
                setIsCreateModalVisible(false);
                setIsUpdated(!isUpdated);

            } catch (e) {
                setError(e.data);
                setLoading(false);
                message.error("Sistemdə baş verdi");
            }

        } catch (e) {
            message.error("Məlumatı düzgün daxil edin");
            setLoading(false);
        }
    }

    const updateStuff = async () => {

        setIsUpdateLoading(true);

        try {
            await form2.validateFields();

            try {
                const formResult = form2.getFieldsValue();

                delete formResult["is_global"];
                delete formResult["assignment_type"];
                delete formResult["organization_id"];
                delete formResult["regions"];
                if(formResult.assigmnts_all) {
                    formResult.assigned_items = null
                   }
                   
                const editingItems = []

                searchUsers.forEach(k => {
                    for(let i = 0; i < formResult.assignment_observation_groups.length; i++) {
                        if(formResult.assignment_observation_groups[i] === k.label || formResult.assignment_observation_groups[i] === k.value) editingItems.push( k.value);
                    }
                });


                formResult.assignment_observation_groups = editingItems


                await updateSingleAssignment(editingId, {...formResult});
                setItemSearch([]);
                setEditingItems([]);
                setIsEditingModalVisible(false);
                setIsUpdated(!isUpdated);
                setIsUpdateLoading(false);
                setError(null);
                setEditingError(null);
                message.success("Təyinat yeniləndi");


            } catch (e) {
                setEditingError(e.data);
                setIsUpdateLoading(false);
                message.error("Sistemdə baş verdi");
            }

        } catch (e) {
            message.error("Məlumatı düzgün daxil edin");
            setIsUpdateLoading(false);
        }
    }

    const fetchItems = _.debounce((e, form = form1, except = false) => {
        setLoading(true);
        const params = form.getFieldsValue();
        let exceptAr = [];

        if (except) {
            exceptAr = editingItems.map(k => k.value);

        }
        getAssignmentsRegionSearch({
            is_global: params.is_global,
            regions: isRegionGlobal ? [0] : params.regions,
            assignment_type: params.assignment_type,
            name: e,
            exclude: exceptAr
        }).then((e) => setItemSearch(e.data.map(t => ({
            label: t.field_code + " / " + t.fhn_id + " / " + shortendName(t.product_name),
            value: t.id,
            key: t.id
        })))).finally(() => setLoading(false))

    }, 500, {trailing: true})

    const fetchUsers = (e, form = form1, except = false) => {
        let exceptAr = [];

        if (except) {
            exceptAr = editingAudit.map(k => k.value);
        }

        getUsersForAssignments({name: e, exclude: exceptAr}).then(k => setSearchUsers(k));

    }

    useEffect(() => {
        const fetchData = async () => {
            setOrgs(await getOrganizationsSelect());
            setSearchUsers(await getUsersForAssignments({exclude: editingAudit}));
        }

        fetchData();
    }, [editingId, isEditingModalVisible, isCreateModalVisible]);


    const fetchEditingRecord = (id) => {
        setIsUpdateLoading(true);
        setEditingId(id);
        getSingleAssignmentById(id).then(async (k) => {
            setIsUpdateLoading(false)
            setEditingAudit(k.assignment_observation_groups);


            const groups = await getNomencGroupSelect(k.assignment_type)
            setNomencGroups(groups.map((t) => ({...t, label: `${t.title} (${t.totalItems})`})))


            setEditingItems(k.assigned_items?.map(
                t => (
                    {
                        label: t.field_code + " / " + t.fhn_id + " / " + shortendName(t.product_name),
                        value: t.id,
                        key: t.id
                    }
                ),
            ));
            setEditingOrg([k.organization_id]);
            
            form2.setFieldsValue({
                ...k,
                assignment_observation_groups: k.assignment_observation_groups.map(t => t.label),
                assigned_items: k.assigned_items?.map(t => t.id),
                assigned_groups: k.assigned_groups?.map(t => t.value),
                organization_id: k.organization_id.value,
            });

        }).catch(e => {}).finally(() => setIsUpdateLoading(false));
    }

    const onEditingItemsClose = (id) => {
        setEditingItems(editingItems.filter(k => k.key !== id));
    }

    const onEditingAuditClose = (id) => {
        setEditingAudit(editingAudit.filter(k => k.key !== id));
    }

    const onChangeCheck = (e) => {
        setFormState(6);
    };

    return (
        <div>
            <PageHeading title={"Təyinatlar"} path={"Panel/Tənzimləmələr/Təyinatlar"}>
                <div>
                    <Button type={"primary"} onClick={() => {
                        setIsCreateModalVisible(true);
                        setFormState(0)

                    }}>
                        Əlavə et
                    </Button>
                </div>
            </PageHeading>

            <Modal
                visible={isCreateModalVisible}
                cancelText={"İmtina et"}
                width={1000}
                onOk={postStuff}
                maskClosable={false}
                confirmLoading={loading}
                keyboard={false}
                onCancel={(e) => {
                    if (form1.isFieldsTouched()) {
                        if (window.confirm("Diqqət! Daxil etdiyiniz məlumat itəcək!")) {
                            form1.resetFields();
                            setFormState(0);
                            setEditingItems([]);
                            setNomencGroups([]);
                            setItemSearch([]);
                            return setIsCreateModalVisible(false)
                        }
                        return
                    }
                    form1.resetFields();
                    setFormState(0);
                    setIsCreateModalVisible(false)
                }}
                title={"Məlumat daxil edin"}>
                <Form name="basic" form={form1} layout={'vertical'} scrollToFirstError>
                    <Form.Item
                        name={"organization_id"}
                        label={"Təşkilat seçin"}
                        rules={[
                            {
                                required: true,
                                message: "Təyinatın növünü seçin"
                            }
                        ]}
                    >
                        <TreeSelect
                            onSelect={() => setFormState(1)}
                            disabled={formState > 1}
                            onClear={() => setFormState(0)}
                            placeholder={"Təşkilat seçin"}
                            allowClear loading={!orgs} treeData={orgs}/>
                    </Form.Item>
                    <div style={{display: formState >= 1 ? "block" : "none"}}>
                        <Form.Item

                            name={"assignment_type"}
                            label={"Təyinatın növü"}
                            rules={[
                                {
                                    required: true,
                                    message: "Təyinatın növünü seçin"
                                }
                            ]}
                        >
                            <Select
                                onSelect={(e) => {
                                    setFormState(2);
                                    setAssignmentType(e)
                                }}
                                disabled={formState > 2}
                                onClear={() => setFormState(1)}
                                placeholder={"Təyinatın növünü seçin"}
                                allowClear options={ASSIGNMENT_TYPES}/>
                        </Form.Item>
                    </div>
                    <div style={{display: formState >= 2 ? "block" : "none"}}>
                        <Form.Item
                            name={"is_global"}
                            label={"Region üzrə qiymət qoyma tipi"}

                            rules={[
                                {
                                    required: true,
                                    message: "Region üzrə qiymət qoyma tipini seçin"
                                }
                            ]}
                        >
                            <Select
                                onSelect={(l) => {
                                    setFormState(l === 1 ? 4 : 3);
                                    setIsRegionGlobal(l === 1);
                                    setRegions(l === 1 ? GLOBAL_REGION : REGIONS);
                                }}
                                disabled={formState > 3}
                                onClear={() => setFormState(2)}
                                placeholder={"Region üzrə qiymət qoyma tipi"}
                                allowClear options={REGION_TYPES}/>
                        </Form.Item>
                    </div>
                    <div style={{display: formState >= 3 ? "block" : "none"}}>
                        {
                            !isRegionGlobal && <Form.Item
                                name={"regions"}
                                label={"Region Seçin"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Region seçilməlidir!"
                                    }
                                ]}
                            >
                                <Select
                                    onSelect={() => setFormState(4)}
                                    mode={"multiple"}
                                    disabled={formState > 4}
                                    placeholder={"Region seçin"}
                                    onClear={() => setFormState(3)}
                                    options={regions}
                                    allowClear/>
                            </Form.Item>
                        }
                    </div>
                    <div style={{display: formState >= 4 ? "block" : "none"}}>
                        <Form.Item
                            name={"assignment_select_type"}
                            label={"Məhsul seçmə tipi"}
                            rules={[
                                {
                                    required: true,
                                    message: "Məhsul seçin"
                                }
                            ]}
                        >
                            <Radio.Group
                                onChange={async (k) => {
                                    setAssignmentSelectType(k.target.value);
                                    form1.setFieldsValue({assigned_groups: [], assigned_items: []})
                                    setFormState(5)
                                    const groups = await getNomencGroupSelect(assignmentType)
                                    setNomencGroups(groups.map((t) => ({...t, label: `${t.title} (${t.totalItems})`})))
                                }}
                            >
                                <Radio value={0}>Qruplara uyğun</Radio>
                                <Radio value={1}>Məhsullara uyğun</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    {
                        assignmentSelectType === 1 && !assigmnts_all_for_form1 && (
                            <div style={{display: formState >= 5 ? "block" : "none"}}>
                                <Form.Item
                                    name={"assigned_items"}
                                    label={"Təyinata uyğun məhsulları seçin"}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Məhsul seçin"
                                        }
                                    ]}
                                >
                                    <Select
                                        onSelect={() => setFormState(6)}
                                        disabled={formState > 6}
                                        onClear={() => setFormState(isRegionGlobal ? 4 : 5)}
                                        placeholder={"Ad və ya sahə kodu üzrə axtarış üzrə axtarış"}
                                        mode={"multiple"}
                                        allowClear
                                        showSearch={true}
                                        filterOption={false}
                                        optionLabelProp={"label"}
                                        onSearch={(e) => fetchItems(e)} options={itemSearch ? itemSearch : []}/>
                                </Form.Item>
                            </div>
                        )
                    }
                    {assignmentSelectType === 1 && (
                        <div style={{display: formState >= 5 ? "block" : "none"}}>
                            <Form.Item valuePropName="checked" name={"assigmnts_all"}>
                            <Checkbox onChange={onChangeCheck} defaultChecked={checked} value={checked}>Bütün məhsulları seç</Checkbox>
                            </Form.Item>
                        </div>)
                    }
                    { assignmentSelectType === 0 && (
                            <div style={{display: formState >= 5 ? "block" : "none"}}>
                                <Form.Item
                                    name={"assigned_groups"}
                                    label={"Təyinat qrupları"}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Qrup seçin"
                                        }
                                    ]}
                                >
                                    <Select
                                        onSelect={() => setFormState(6)}
                                        disabled={formState > 6}
                                        onClear={() => setFormState(isRegionGlobal ? 4 : 5)}
                                        placeholder={"Qrup adı üzrə axtarış"}
                                        mode={"multiple"}
                                        allowClear
                                        showSearch={true}
                                        filterOption={false}
                                        options={nomencGroups}/>
                                </Form.Item>
                            </div>
                        )
                    }
                    <div style={{display: formState >= 6  ? "block" : "none"}}>
                        <Form.Item
                            name={"assignment_observation_groups"}
                            label={"Təyinata uyğun müşahidəçilər seçin"}
                            rules={[
                                {
                                    required: true,
                                    message: "Təyinata uyğun müşahidəçilər seçin"
                                }
                            ]}
                        >
                            <Select
                                onSelect={() => setFormState(7)}
                                disabled={formState > 7}
                                onClear={() => setFormState(5)}
                                placeholder={"Ad üzrə axtarış üzrə axtarış"}
                                mode={"multiple"}
                                allowClear
                                showSearch={true}
                                filterOption={false}
                                optionLabelProp={"label"}
                                options={searchUsers ? searchUsers : []}/>
                        </Form.Item>
                    </div>
                    <div style={{display: formState >= 7 ? "block" : "none"}}>
                        <Form.Item
                            name={"maximum_delay_days"}
                            label={"Maksimum geçikmə günü"}
                            rules={[
                                {
                                    required: true,
                                    message: "Geçikmə günü daxil edin"
                                }
                            ]}
                        >
                            <InputNumber min={1} placeholder={10} style={{width: "100%"}} max={1000}/>
                        </Form.Item>
                        <Form.Item
                            name={"renewal_periodic_days"}
                            label={"Yenilənmə dövrü"}
                            rules={[
                                {
                                    required: true,
                                    message: "Yenilənmə dövrü daxil edin"
                                }
                            ]}
                        >
                            <InputNumber min={1} placeholder={10} style={{width: "100%"}} max={1000}/>
                        </Form.Item>
                        <Form.Item
                            name={"suspicious_percentage"}
                            label={"Riskli artım faizi"}
                            rules={[
                                {
                                    required: true,
                                    message: "Riskli artım faizi daxil edin"
                                }
                            ]}
                        >
                            <InputNumber min={1} placeholder={10} style={{width: "100%"}} max={1000}/>
                        </Form.Item>
                        <Form.Item
                            name={"risky_handling_type"}
                            label={"Riskli qiymət prosedur növü"}
                            placeholder={"Növ seçin"}
                            rules={[
                                {
                                    required: true,
                                    message: "Riski qiymət prosedur növü seçin"
                                }
                            ]}
                        >
                            <Select placeholder={"Növ seçin"}>
                                <Select.Option value={0}>Yalnız riskli qiymətlər müşahidə qrupuna
                                    yönləndirilsin</Select.Option>
                                <Select.Option value={1}>Bütün təsdiq edilmiş qiymətlər müşahidə qrupuna
                                    yönləndirilsin</Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                </Form>
                {
                    (() => {
                        if (error) {
                            return <ErrorHolder error={error}/>
                        }
                    })()
                }
            </Modal>


            {/*Editing Modal*/}

            <Modal
                cancelText={"İmtina et"}
                visible={isEditingModalVisible}
                onOk={updateStuff}
                width={1000}
                confirmLoading={isUpdateLoading}
                maskClosable={false}
                onCancel={(e) => {
                    if (form2.isFieldsTouched()) {
                        if (window.confirm("Diqqət! Daxil etdiyiniz məlumat itəcək!")) {
                            form2.resetFields();
                            setEditingOrg([]);
                            setEditingAudit([]);
                            setEditingItems([]);
                            setRegions([]);
                            return setIsEditingModalVisible(false)
                        }
                        return
                    }
                    form2.resetFields();
                    setIsEditingModalVisible(false)
                }}
                keyboard={false}
                title={"Redaktə edin"}
            >
                <Form name="basic" form={form2} layout={'vertical'} scrollToFirstError>
                    <Form.Item
                        label={"Təşkilat"}
                        name={"organization_id"}
                    >
                        <Select disabled options={editingOrg}/>
                    </Form.Item>
                    <Form.Item
                        label={"Təyinatın növü"}
                        name={"assignment_type"}
                    >
                        <Select disabled options={ASSIGNMENT_TYPES}/>
                    </Form.Item>
                    <Form.Item
                        name={"is_global"}
                        label={"Region üzrə qiymət qoyma tipi"}
                    >
                        <Select disabled options={REGION_TYPES}/>
                    </Form.Item>
                    <Form.Item
                        label={"Regionlar"}
                        name={"regions"}
                    >
                        <Select disabled mode={"multiple"} options={[...REGIONS, ...GLOBAL_REGION]}/>
                    </Form.Item>
                    <Form.Item
                        label={"Müşahidə qrupu"}
                        name={"assignment_observation_groups"}
                        rules={[
                            {
                                required: true,
                                message: "Müşahidə qrupu təyin edin"
                            }
                        ]}
                    >
                        <Select mode={"multiple"} options={searchUsers} allowClear/>
                    </Form.Item>
                    {
                        editingItems && !assigmnts_all_for_form2 &&  (
                            <Form.Item
                                label={"Təyinata uyğun məhsullar seçin (Əlavə üçün axtarış edin)"}
                                name={"assigned_items"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Qrup seçin"
                                    }
                                ]}
                            >
                                <Select mode={"multiple"}
                                        showSearch={true}
                                        filterOption={false}
                                        optionLabelProp={"label"}
                                        onSearch={(e) => fetchItems(e, form2, true)}
                                        options={[...itemSearch, ...editingItems]}
                                        allowClear/>
                            </Form.Item>
                        )
                    }
                    {editingItems && (
                            <Form.Item valuePropName="checked" name={"assigmnts_all"}>
                            <Checkbox>Bütün məhsulları seç</Checkbox>
                            </Form.Item>
                       )
                    }
                    {
                        !editingItems && (
                            <Form.Item
                                label={"Təyinata uyğun qruplar"}
                                name={"assigned_groups"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Qrup seçin"
                                    }
                                ]}
                            >
                                <Select mode={"multiple"}
                                        options={nomencGroups} allowClear/>
                            </Form.Item>
                        )
                    }
                    <Form.Item
                        name={"maximum_delay_days"}
                        label={"Maksimum geçikmə günü"}
                        rules={[
                            {
                                required: true,
                                message: "Geçikmə günü daxil edin"
                            }
                        ]}
                    >
                        <InputNumber min={1} placeholder={10} style={{width: "100%"}} max={1000}/>
                    </Form.Item>
                    <Form.Item
                        name={"renewal_periodic_days"}
                        label={"Yenilənmə dövrü"}
                        rules={[
                            {
                                required: true,
                                message: "Yenilənmə dövrü daxil edin"
                            }
                        ]}
                    >
                        <InputNumber min={1} placeholder={10} style={{width: "100%"}} max={1000}/>
                    </Form.Item>
                    <Form.Item
                        name={"suspicious_percentage"}
                        label={"Riskli artım faizi"}
                        rules={[
                            {
                                required: true,
                                message: "Riskli artım faizi daxil edin"
                            }
                        ]}
                    >
                        <InputNumber min={1} placeholder={10} style={{width: "100%"}} max={1000}/>
                    </Form.Item>
                    <Form.Item
                        name={"risky_handling_type"}
                        label={"Riskli qiymət prosedur növü"}
                        placeholder={"Növ seçin"}
                        rules={[
                            {
                                required: true,
                                message: "Riski qiymət prosedur növü seçin"
                            }
                        ]}
                    >
                        <Select placeholder={"Növ seçin"}>
                            <Select.Option value={0}>Yalnız riskli qiymətlər müşahidə qrupuna
                                yönləndirilsin</Select.Option>
                            <Select.Option value={1}>Bütün təsdiq edilmiş qiymətlər müşahidə qrupuna
                                yönləndirilsin</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>

                {
                    (() => {
                        if (editingError) {
                            return <ErrorHolder error={editingError}/>
                        }
                    })()
                }
            </Modal>

            <div>
                <ExtendedTable
                    update={isUpdated}
                    history={history}
                    fetchFunction={getAssignments}
                    actions={{
                        custom: [
                            {
                                render: (record) => !record.isEditable ?
                                    <Tooltip title={"Təyinata uyğun əməliyyatlar var"}><Button
                                        disabled={!record.isEditable}
                                        size={"small"}><EditOutlined/></Button></Tooltip> :
                                    <Button disabled={!record.isEditable} size={"small"}><EditOutlined/></Button>,
                                onClick: (id, record) => {
                                    if (record.isEditable) {
                                        fetchEditingRecord(id);
                                        setIsEditingModalVisible(true)
                                    }
                                }
                            },
                            {
                                render: (record) => <Button
                                    size={"small"}
                                    style={{
                                        marginLeft: "10px",
                                        width: "100px",
                                        // color: record.status === 1 ? "orangered" : "limegreen"
                                    }}>{record.status === 1 ? "Deaktiv et" : "Aktiv et"}</Button>,
                                onClick: (id) => {
                                    toggleAssignment(id).then(k => setIsUpdated(!isUpdated));
                                }
                            }
                        ]
                    }}
                />
            </div>
        </div>
    )
}
