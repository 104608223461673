import {
  Button,
  Form,
  Table,
  Tag,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Typography,
  Pagination,
  message,
  Popover,
} from "antd";
import Animate from "rc-animate";
import qs from "qs";
import {
  EditOutlined,
  UpOutlined,
  DownOutlined,
  CopyOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import ButtonGroup from "antd/lib/button/button-group";
import { useEffect, useState } from "react";
import useForm from "antd/es/form/hooks/useForm";
import PopoverUserToolTip from "./PopoverUserToolTip";
import TweenOne from "rc-tween-one";

const { Text } = Typography;

function ExtendedTable({
  actions,
  fetchFunction,
  history,
  expandable,
  update,
  onClick,
  reset,
  persistKeys = {},
  rowSelection,
}) {
  const [form] = useForm();
  const [firstMount, setFirstMount] = useState(true);
  const [isSearchCollapsed, setIsSearchCollapsed] = useState(true);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({ limit: 10, page: 1 });
  const [total, setTotal] = useState(0);
  const [dts, setDts] = useState();
  const [columns, setColumns] = useState();
  const [filters, setFilters] = useState();
  const [data, setData] = useState();
  const [firstFilters, setFirstFilters] = useState();
  const [lastFilters, setLastFilters] = useState();

  const columnDataStructureToAntdNotHidden = (dts = []) => {
    const columns = [];
    dts.forEach((item, index) => {
      if (!item.hidden) {
        if (item.columnType === "Text") {
          columns.push({
            title: item.displayName,
            dataIndex: item.keyName,
            width: index === 0 ? "120px" : "auto",
            key: item.keyName,
            render: (text, record) => {
              return {
                props: {
                  style: {
                    width: item.width ?? "auto",
                  },
                },
                children: (
                  <Text style={{ textOverflow: "ellipsis" }}>{text}</Text>
                ),
              };
            },
          });
        }

        if (item.columnType === "Enum") {
          columns.push({
            title: item.displayName,
            // width: "180px",
            dataIndex: item.keyName,
            key: item.keyName,
            render: (text) => {
              return (
                <Text color={text === 1 ? "limegreen" : "orangered"} key={text}>
                  {
                    item.possibleEnums.filter(
                      (el) => el.value === text || el.text === text
                    )[0].text
                  }
                </Text>
              );
            },
          });
        }

        if (item.columnType === "User") {
          columns.push({
            title: item.displayName,
            dataIndex: item.keyName,
            // width: "100px",
            key: item.keyName,
            render: (text, record) => (
              <Popover
                title={text}
                content={() => (
                  <PopoverUserToolTip id={record[item.keyName + "_id"]} />
                )}
              >
                <Text>{text}</Text>
              </Popover>
            ),
          });
        }

        if (item.columnType === "Copyable") {
          columns.push({
            title: item.displayName,
            dataIndex: item.keyName,
            // width: "100px",
            key: item.keyName,
            render: (text, record) => (
              <div>
                <Text code>{text}</Text>
                <Text
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigator.clipboard.writeText(text);
                    message.info(`${item.displayName} kopyalandı`);
                  }}
                  code
                >
                  <CopyOutlined />
                </Text>
              </div>
            ),
          });
        }

        if (item.columnType === "Array") {
          columns.push({
            title: item.displayName,
            dataIndex: item.keyName,
            key: item.keyName,
            render: (users) => {
              if (item.childColumnType === "User") {
                return users.map((k, i) => (
                  <Popover
                    title={k.name}
                    key={i}
                    content={() => <PopoverUserToolTip id={k.id} />}
                  >
                    <Tag>{k.name}</Tag>
                  </Popover>
                ));
              }
            },
          });
        }

        if (item.columnType === "Date") {
          columns.push({
            title: item.displayName,
            // width: "100px",
            dataIndex: item.keyName,
            key: item.keyName,
          });
        }

        if (item.columnType === "status" || item.columnType === "Status") {
          columns.push({
            title: item.displayName,
            dataIndex: item.keyName,
            // width: "60px",
            key: item.keyName,
            render: (status) => {
              const color = item.possibleEnums.filter(
                (el) => el.value === status
              )[0]?.color;
              return (
                <Tag style={{ overflowWrap: "break-word" }} key={status}>
                  {
                    item.possibleEnums.filter((el) => el.value === status)[0]
                      ?.text
                  }
                </Tag>
              );
            },
          });
        }
      }
    });

    if (actions) {
      const extraActions = [];

      if (actions.edit) {
        extraActions.push((text, record) => (
          <Button
            style={{ marginLeft: "5px", marginRight: "5px" }}
            icon={<EditOutlined />}
            onClick={() => actions.edit(record.id)}
          />
        ));
      }

      if (actions.custom) {
        actions.custom.forEach((each, index) =>
          extraActions.push((text, record) => {
            const shownStatus = each.isShown
              ? each.isShown(text, record)
              : true;

            if (shownStatus) {
              if (each.render) {
                return (
                  <div onClick={() => each.onClick(record.id, record)}>
                    {each.render(record)}
                  </div>
                );
              } else {
                return (
                  <Button
                    style={{ marginLeft: "5px", marginRight: "5px" }}
                    key={index}
                    icon={each.icon}
                    onClick={() => each.onClick(record.id, record)}
                  />
                );
              }
            }
          })
        );
      }

      columns.push({
        title: "Əməliyyatlar",
        key: "action",
        render: (text, record) => (
          <ButtonGroup size={"middle"}>
            {extraActions.map((el, k) => el(text, record))}
          </ButtonGroup>
        ),
      });
    }

    return columns;
  };

  const buildFilters = (dts = []) => {
    const filters = [];

    const filtersFirst = [];
    const filtersRest = [];

    dts.forEach((item, index) => {
      if (item.filters) {
        const fil = (
          <Col span={8} key={item.keyName}>
            <Form.Item
              labelAlign={"left"}
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              label={
                item.keyName === "id" ? "İdentifikasiya kodu" : item.displayName
              }
              name={item.keyName}
            >
              {(() => {
                if (
                  item.columnType === "Text" ||
                  item.columnType === "User" ||
                  item.columnType === "Copyable"
                ) {
                  return (
                    <Input
                      allowClear
                      autoComplete="off"
                      placeholder={`${
                        item.keyName === "id"
                          ? "İdentifikasiya kodu"
                          : item.displayName
                      } üzrə axtarış`}
                    />
                  );
                }
                if (
                  item.columnType === "status" ||
                  item.columnType === "Status" ||
                  item.columnType === "Enum"
                ) {
                  return (
                    <Select
                      allowClear
                      placeholder={"Seç"}
                      mode={item.multi ? "multiple" : "single"}
                    >
                      {item.possibleEnums.map((el, k) => (
                        <Select.Option key={k} value={`${el.value}`}>
                          {el.text}
                        </Select.Option>
                      ))}
                    </Select>
                  );
                }
                if (item.columnType === "Date") {
                  return (
                    <DatePicker
                      style={{ width: "100%" }}
                      format={"YYYY-MM-DD"}
                      autoComplete="off"
                      allowClear
                      placeholder={"Tarix seçin"}
                    />
                  );
                }
              })()}
            </Form.Item>
          </Col>
        );

        if (filtersFirst.length < 3) filtersFirst.push(fil);
        else filtersRest.push(fil);
      }
    });
    return { first: filtersFirst, rest: filtersRest };
  };

  useEffect(() => {
    const url = new URL(window.location);
    const parsed = qs.parse(url.search.substring(1, url.search.length));

    let pag = {
      limit: parseInt(parsed.limit) || pagination.limit,
      page: parseInt(parsed.page) || pagination.page,
    };

    let fil = {};

    for (const [key, value] of Object.entries(parsed)) {
      if (key !== "limit" && key !== "page" && key !== "module_type") {
        fil[key] = value;
      }
    }

    setFilters(fil);
    form.setFieldsValue(fil);
    setPagination(pag);
    fetch({ ...fil, ...pag });
    setFirstMount(false);
  }, []);

  const fetch = (params) => {
    setLoading(true);

    for (const [key, value] of Object.entries(params)) {
      if (params[key] === undefined || params[key] === "") delete params[key];
    }

    fetchFunction(params)
      .then((res) => {
        setDts(res.columns);
        const { first, rest } = buildFilters(res.columns);
        setFirstFilters(first);
        setLastFilters(rest);
        setColumns(columnDataStructureToAntdNotHidden(res.columns));
        setData(res.data.map((el) => ({ ...el, key: el.id || el.key })));
        setTotal(res.total);
      }).finally(() => setLoading(false));
  };

  // Use is not first load problem

  useEffect(() => {
    if (!firstMount) {
      fetch({ ...filters, ...pagination });
    }
  }, [update]);

  useEffect(() => {
    if (!firstMount) {
      setFilters({});
      setPagination({ limit: 10, page: 1 });
      fetch({ limit: 10, page: 1 });
    }
  }, [reset]);

  useEffect(() => {
    if (filters || pagination) {
      const lk = { ...filters, ...pagination };

      for (const [key, value] of Object.entries(lk)) {
        if (lk[key] === undefined || lk[key] === "") delete lk[key];
      }
      const url = new URL(window.location);

      const parsed = qs.parse(url.search.substring(1, url.search.length));
      history.replace(
        decodeURIComponent(
          `${window.location.pathname}?${qs.stringify(
            {
              ...lk,
              module_type: parsed.module_type,
            },
            { arrayFormat: "brackets" }
          )}`
        )
      );
    }
  }, [filters, pagination]);

  return (
    <div>
      {loading ? (
        <></>
      ) : (
        <div
          style={{
            display: !lastFilters ? "none" : "block",
            marginBottom: "10px",
            backgroundColor: "white",
            padding: "15px",
          }}
        >
          <Row span={24}>
            <Col span={12}>
              <h2>Axtarış</h2>
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <div>
                {(() => {
                  if (lastFilters) {
                    return (
                      <Button
                        style={{
                          fontSize: 12,
                          marginLeft: "10px",
                          width: "130px",
                        }}
                        onClick={() => {
                          setIsSearchCollapsed(!isSearchCollapsed);
                        }}
                      >
                        {!isSearchCollapsed ? <UpOutlined /> : <DownOutlined />}{" "}
                        {isSearchCollapsed ? "Bütün filterlər" : "Gizlət"}
                      </Button>
                    );
                  }
                })()}
                <Button
                  style={{ fontSize: 12, marginLeft: "10px" }}
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                    message.info(`Filter kopyalandı`);
                  }}
                >
                  <CopyOutlined /> Filterləri kopyala
                </Button>
              </div>
            </Col>
          </Row>
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={() => {
              setFilters(form.getFieldsValue());
              fetch(form.getFieldsValue());
              setPagination({ ...pagination, page: 1 });
            }}
            name="basic"
            form={form}
          >
            <Row gutter={30}>
              {firstFilters}
              {isSearchCollapsed ? <></> : lastFilters}
            </Row>
            <Row gutter={24} style={{ marginTop: "10px" }}>
              <Col span={24} style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100px" }}
                >
                  Axtar
                </Button>
                <Button
                  loading={loading}
                  style={{ marginLeft: "10px", width: "100px" }}
                  type="secondary"
                  onClick={() => {
                    form.resetFields();
                    setFilters(form.getFieldsValue());
                    fetch(pagination);
                  }}
                >
                  Təmizlə
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
      <div style={{ overflowX: "auto", overflowY: "hidden" }}>
        <Table
          rowSelection={rowSelection}
          locale={{ emptyText: "Məlumat yoxdur" }}
          style={{
            overflowX: "scroll",
            backgroundColor: "white",
            maxHeight: "55vh",
          }}
          loading={loading}
          dataSource={data}
          expandable={expandable}
          pagination={false}
          columns={columns}
        />
        <Pagination
          style={{ marginTop: "10px", textAlign: "right" }}
          onChange={(k, l) => {
            setPagination({ page: k, limit: l });
            fetch({ ...filters, page: k, limit: l });
          }}
          current={pagination.page}
          pageSize={pagination.limit}
          total={total}
          defaultPageSize={pagination.limit}
          showSizeChanger
          pageSizeOptions={["10", "20", "30", "50", "100"]}
        />
      </div>
    </div>
  );
}

export default ExtendedTable;