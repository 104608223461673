import {Form, message} from "antd";
import {useEffect, useState} from "react";
import Modal from "antd/es/modal/Modal";
import ErrorHolder from "../components/ErrorHolder";

export default function GenericFormModal(
    {
        title,
        isModalVisible,
        extraLoading = false,
        setIsModalVisible,
        recordId = null,
        ignoreRecord = false,
        mainFunctions = null,
        dataManipulation,
        dataEntrance,
        update,
        onClose = () => {
        },
        onSubmit = () => {
        },
        ignoreRecordId = false,
        width,
        children,
        fields,
    }
) {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [record, setRecord] = useState();
    const [error, setError] = useState();
    const [modalLoading, setModalLoading] = useState(false);

    useEffect(() => {
        async function fetchSomeData() {
            setModalLoading(true);
            if (recordId && !ignoreRecord) {
                try {
                    const record = await mainFunctions.get(recordId);
                    setRecord(record);
                    form.setFieldsValue(dataEntrance ? dataEntrance(record) : record);
                } catch (e) {
                    setError({message: "Məlumat tapılmadı", errors: []})
                }
            }
        }

        if (isModalVisible) fetchSomeData().finally(() => setModalLoading(false));

    }, [recordId, isModalVisible])

    const sendData = async () => {
        setIsLoading(true)
        try {
            await form.validateFields();
            let data = form.getFieldsValue();

            if (dataManipulation) {
                data = dataManipulation(data);
            }

            try {
                if (record && !ignoreRecord) {
                    if (ignoreRecordId) {
                        await mainFunctions.update(data)
                    } else {
                        await mainFunctions.update(recordId, data)
                    }

                } else {
                    await mainFunctions.create(data);

                }
                message.success("Əməliyyat uğurla tamamlandı!");
                onSubmit();
                if (update) update();
                setIsModalVisible(false);
                setIsLoading(false);
                form.resetFields();
                setError(null);
                return;
            } catch (e) {
                setError(e.data);
                message.error("Sistemdə baş verdi");
                setIsLoading(false)
            }

        } catch (e) {
            message.error("Sistemdə xəta baş verdi")
            setIsLoading(false)
        }
    }

    return (
        <Modal
            confirmLoading={isLoading || modalLoading || extraLoading}
            title={title ? title : record && !ignoreRecord ? `Redaktə et ${!mainFunctions ? "(Main functions are missing)" : ""}` : `Məlumat daxil edin ${!mainFunctions ? "(Main functions are missing)" : ""}`}
            visible={isModalVisible}
            okText={"Təsdiq et"}
            cancelText={"İmtina et"}
            width={width}
            onOk={mainFunctions ? sendData : () => alert("Feature is not available")}
            maskClosable={false}
            keyboard={false}
            onCancel={(e) => {
                onClose();
                if (form.isFieldsTouched()) {
                    if (window.confirm("Diqqət! Daxil etdiyiniz məlumat itəcək!")) {
                        form.resetFields();
                        setRecord(null);
                        setError(null);
                        return setIsModalVisible(false)
                    }
                    return
                }
                form.resetFields();
                setIsModalVisible(false)
                setError(null);
            }}>
            <Form
                onValuesChange={() => setError(undefined)} layout={'vertical'} form={form} name={"add-load-class"}
                scrollToFirstError
                fields={fields}>
                {children}
            </Form>
            {
                (() => {
                    if (error) {
                        return <ErrorHolder error={error}/>
                    }
                })()
            }
        </Modal>
    )
}