import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import ExtendedTable from "../../components/ExtendedTable";
import {UserAddOutlined} from '@ant-design/icons'
import {Button, Form, Input, Radio, Select, TreeSelect} from 'antd';
import PageHeading from "../../components/PageHeading";
import {
    createUser,
    getAllUsers,
    getGroups,
    getOrganizationsSelect,
    getUser,
    updateUser
} from "../../adapters/api.adapter";
import GenericFormModal from "../../forms/genericFormModal";


const mobilePrefix = [
    "050",
    "051",
    "055",
    "060",
    "070",
    "077",
    "099"
]

const parseSerialNumber = (serialNumber) => {
    return serialNumber.length === 7 ? {
        prefix: serialNumber.substring(0, 2),
        number: serialNumber.substring(2, 10)
    } : {
        prefix: serialNumber.substring(0, 3),
        number: serialNumber.substring(3, 11)
    }
}


const numberPrefixSelector = (
    <Form.Item name="mobilePrefix" noStyle>
        <Select
            placeholder={"Seç"}
            style={{
                width: 70,
            }}
            defaultValue={mobilePrefix[0]}
            rules={[
                {
                    required: true,
                    message: "Mobil prefiks seçilməlidir!"
                }

            ]}
        >
            {
                (() => {
                    return mobilePrefix.map((d, k) => <Select.Option key={k + Math.random() * 100}
                                                                     value={d}>{d}</Select.Option>)
                })()
            }
        </Select>
    </Form.Item>
);

const serialPrefixSelector = (
    <Form.Item name="serialPrefix" noStyle>
        <Select
            placeholder={"Seç"}
            defaultValue={"AA"}
            style={{
                width: 70,
            }}
            rules={[
                {
                    required: true,
                    message: "Ş.V. prefiks seçilməlidir!"
                }
            ]}
        >
            <Select.Option value="AA">AA</Select.Option>
            <Select.Option value="AZE">AZE</Select.Option>
        </Select>
    </Form.Item>
);


const toInputUppercase = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
};

export default function Users() {
    const history = useHistory()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingRecordId, setEditingRecordId] = useState();
    const [ignoreRecord, setIgnoreRecord] = useState();
    const [groups, setGroups] = useState();
    const [orgs, setOrgs] = useState();
    const [isUpdated, setIsUpdated] = useState();

    useEffect(() => {
        const fetchData = async () => {
            setGroups(await getGroups());
            setOrgs(await getOrganizationsSelect());
        }

        fetchData();
    }, [])

    return (
        <div>
            <PageHeading title={"İstifadəçilər"} path={"Panel/Tənzimləmələr/İstifadəçilər"}>
                <div>
                    <Button icon={<UserAddOutlined/>} type={"primary"} onClick={() => {
                        setIsModalVisible(true);
                        setIgnoreRecord(true)
                    }}>
                        Əlavə et
                    </Button>
                </div>
            </PageHeading>
            <GenericFormModal
                update={() => setIsUpdated(!isUpdated)}
                ignoreRecord={ignoreRecord}
                recordId={editingRecordId}
                setIsModalVisible={setIsModalVisible}
                isModalVisible={isModalVisible}
                mainFunctions={{get: getUser, update: updateUser, create: createUser}}
                dataEntrance={(data) => {
                    return {
                        ...data,
                        serialNumber: parseSerialNumber(data.serialNumber).number,
                        serialPrefix: parseSerialNumber(data.serialNumber).prefix,
                        mobilePrefix: data.phoneNumber ? data.phoneNumber.substring(0, 3) : "050",
                        phoneNumber: data.phoneNumber ? data.phoneNumber.substring(3, 10) : "",
                    }
                }}
                dataManipulation={(data) => {
                    let sanData = {
                        ...data,
                        serialNumber: `${data.serialPrefix}${data.serialNumber}`
                    }
                    
                    if(data.phoneNumber && data.phoneNumber !== "") {
                        sanData = {...sanData, phoneNumber: `${data.mobilePrefix}${data.phoneNumber}`}
                    }

                    delete sanData['mobilePrefix'];
                    delete sanData['serialPrefix'];
                    return sanData;
                }}
                fields={[
                    {
                        name: ["mobilePrefix"],
                        value: mobilePrefix[0],
                    },
                    {
                        name: ["serialPrefix"],
                        value: "AA",
                    }
                ]}
            >
                <Form.Item
                    name={"finCode"}
                    label={"FİN Kod"}
                    rules={[
                        {
                            required: true,
                            message: "FİN Kod daxil edin"
                        }
                    ]}
                >
                    <Input placeholder={"1234567"} onInput={toInputUppercase}/>
                </Form.Item>
                <Form.Item
                    name="serialNumber"
                    label="Ş.V. Seriya nömrəsi"
                    rules={[
                        {
                            required: true,
                            message: 'Ş.V. Seriya nömrəsi daxil edin!',
                        },
                    ]}
                >
                    <Input
                        addonBefore={serialPrefixSelector}
                        placeHolder={"1234567"}
                        style={{
                            width: '100%',
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name={"firstName"}
                    label={"Adı"}
                    rules={[
                        {
                            required: true,
                            message: "Ad daxil edin!"
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name={"lastName"}
                    label={"Soyadı"}
                    rules={[
                        {
                            required: true,
                            message: "Soyad daxil edin!"
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name={"fatherName"}
                    label={"Atasının adı"}
                    rules={[
                        {
                            required: true,
                            message: "Ata adı daxil edin!"
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="gender"
                    label="Cins"
                    rules={[
                        {
                            required: true,
                            message: 'Cins təyin edin!',
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio value={0}>Kişi</Radio>
                        <Radio value={1}>Qadın</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Elektron poçt"
                    rules={[
                        {
                            required: true,
                            message: 'Elektron poçt daxil edin!',
                        },
                    ]}
                >
                    <Input placeholder={"example@website.com"}
                    />
                </Form.Item>
                <Form.Item
                    name="phoneNumber"
                    label="Mobil nömrə"
                >
                    <Input
                        addonBefore={numberPrefixSelector}
                        style={{
                            width: '100%',
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name="position"
                    label="Vəzifə"
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    name="organisation_id"
                    label="Təşkilat"
                    rules={[
                        {
                            required: true,
                            message: 'Təşkilat təyin edin!',
                        },
                    ]}
                >
                    <TreeSelect allowClear treeData={orgs} loading={!orgs} placeholder={"Təşkilatı seçin"}/>
                </Form.Item>
                <Form.Item
                    name="user_group"
                    label="İstifadəçi qrupu"
                    rules={[
                        {
                            required: true,
                            message: 'İstifadəçi qrupu təyin edin!',
                        },
                    ]}
                >
                    <Select options={groups} loading={!groups}/>
                </Form.Item>
                {
                    (() => {
                        if (editingRecordId && !ignoreRecord) {
                            return (
                                <Form.Item
                                    name={"is_active"}
                                    label={"Statusu"}
                                >
                                    <Radio.Group>
                                        <Radio value={0}>Deaktiv</Radio>
                                        <Radio value={1}>Aktiv</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            )
                        }
                    })()
                }
            </GenericFormModal>
            <ExtendedTable
                update={isUpdated}
                history={history}
                fetchFunction={getAllUsers}
                actions={{
                    edit: (id) => {
                        setEditingRecordId(id);
                        setIgnoreRecord(false);
                        setIsModalVisible(true);
                    }
                }}/>
        </div>
    )
}
