import { useHistory } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  List,
  message,
  Row,
  Upload,
  Select,
  Radio,
  Menu,
  Dropdown,
  Spin,
  Tooltip,
} from "antd";
import ExtendedTable from "../../components/ExtendedTable";
import PageHeading from "../../components/PageHeading";
import {
  downloadAttachment,
  getOperatorTask,
  getOperatorTasks,
  getTaskProductsSearch,
  operatorCreateTask,
  putOperatorTask,
  excelImport,
} from "../../adapters/api.adapter";
import {
  FileSearchOutlined,
  HistoryOutlined,
  SecurityScanOutlined,
  InboxOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  SaveOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import GenericFormModal from "../../forms/genericFormModal";
import { useEffect, useState } from "react";
import Modal from "antd/es/modal/Modal";
import ErrorHolder from "../../components/ErrorHolder";
import moment from "moment";
import ExpandedRow from "../../components/ExpandedRow";
import saveAs from "file-saver";
import Text from "antd/es/typography/Text";
import ExportExcelModal from "components/ExportExcelModal";
import FailedShowModal from "components/FailedShowModal";

const { TextArea } = Input;
const { Dragger } = Upload;

const loadingContent = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

export default function Documents() {
  const [form] = Form.useForm();

  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isExcelModalVisible, setIsExcelModalVisible] = useState(false);
  const [failedModalVisible, setFailedModalVisible] = useState(false);
  const [excelRecordId, setExcelRecordId] = useState();
  const [editingRecordId, setEditingRecordId] = useState();
  const [ignoreRecord, setIgnoreRecord] = useState();
  const [isUpdated, setIsUpdated] = useState();
  const [error, setError] = useState();
  const [itemSearch, setItemSearch] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [loading, setLoading] = useState();
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [assignmentType, setAssignmentType] = useState();
  const [resultImport, setResultImport] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const dropDown = [
    {
      type: 1,
      text: "Tikintidə istifadə edilən materiallar üzrə",
    },
    {
      type: 3,
      text: "Tikintidə əməyin ödənilməsi üzrə",
    },
    {
      type: 5,
      text: "Mexanizm istismar xərcləri üzrə",
    },
    {
      type: 2,
      text: "Daşınma üzrə",
    },
  ];

  const menu = (
    <Menu>
      {dropDown.map((l, k) => (
        <Menu.Item
          k={k}
          onClick={() => {
            setExcelRecordId(l.type);
            setIsExcelModalVisible(true);
          }}
          title={l.text}
        >
          {l.text}
        </Menu.Item>
      ))}
    </Menu>
  );

  const fetchItems = (e) => {
    setLoading(true);
    setSelectedItem(undefined);
    getTaskProductsSearch(e)
      .then((e) => setItemSearch(e))
      .finally(() => setLoading(false));
  };

  const handleRegions = (e) => {
    setSelectedItem(e);
  };

  useEffect(() => {
    setFileList([]);
  }, [isModalVisible]);

  const downloadFile = (id, name) => {
    setLoading(true);
    downloadAttachment(id)
      .then(({ data, type }) => {
        const file = new File([data], name, { type: type });
        return saveAs(file);
      })
      .catch((e) => {
        message.error("Xəta!");
      })
      .finally(() => setLoading(false));
  };

  const onFinishExport = () => {
    setLoading(true);
    excelImport(excelRecordId, selectedData.file)
      .then((res) => {
        setResultImport(res);
      })
      .catch((e) => {
        message.error("Xəta!");
      })
      .finally(() => setLoading(false));
  };

  const handleDraggerChange = (data) => {
    setSelectedData(data);
  };

  const postStuff = async () => {
    setLoading(true);

    try {
      await form.validateFields();

      try {
        const assignment_id = itemSearch.filter(
          (k) => k.value === selectedItem
        )[0].assignment_id;

        const fields = form.getFieldsValue();
        fields.pricing_date = fields.pricing_date.format("YYYY-MM-DD HH:mm:ss");

        await operatorCreateTask({ ...fields, assignment_id });
        setIsCreateModalVisible(false);
        form.resetFields();
        message.success("Əməliyyat uğurla tamamlandı!");
        setIsUpdated(!isUpdated);
        setLoading(false);
      } catch (e) {
        setError(e.data);
        message.error("Sistemdə baş verdi");
        setLoading(false);
      }
    } catch (e) {
      message.error("Məlumatı düzgün daxil edin");
      setLoading(false);
    }
  };

  const propsDragger = {
    name: "file",
    multiple: false,
    customRequest: handleDraggerChange,
    showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
  };

  return (
    <div>
      <PageHeading
        title={"Monitorinq Əməliyyatçı Sənədləri"}
        path={"Panel/Əməliyyatlar/Monitorinq Əməliyyatçı Sənədləri"}
      >
        <div>
          <Dropdown overlay={menu}>
            <Button
              loading={loading}
              icon={<SaveOutlined />}
              style={{ marginRight: 10 }}
            >
              Qiymətləndirmə yüklə
            </Button>
          </Dropdown>
          <Button
            type={"primary"}
            onClick={() => {
              setIsCreateModalVisible(true);
            }}
          >
            Əlavə et
          </Button>
        </div>
      </PageHeading>

      <Modal
        visible={isCreateModalVisible}
        cancelText={"Rədd et"}
        loading={loading}
        width={800}
        onOk={postStuff}
        onCancel={(e) => {
          if (form.isFieldsTouched()) {
            if (window.confirm("Diqqət! Daxil etdiyiniz məlumat itəcək!")) {
              form.resetFields();
              return setIsCreateModalVisible(false);
            }
            return;
          }
          form.resetFields();
          setIsCreateModalVisible(false);
        }}
        title={"Məlumat daxil edin"}
      >
        <div>
          <Form
            onValuesChange={() => setError(undefined)}
            onFinish={postStuff}
            fields={[
              {
                name: ["pricing_date"],
                value: moment(),
              },
            ]}
            layout={"vertical"}
            form={form}
            name={"generate task"}
            scrollToFirstError
          >
            <Form.Item
              name={"item_id"}
              label={"Məhsulun adı"}
              rules={[
                {
                  required: true,
                  message: "Məhsul seçin",
                },
              ]}
            >
              <Select
                placeholder={"Ad və ya sahə kodu üzrə axtarış üzrə axtarış"}
                allowClear
                onClear={() => {
                  setSelectedItem(undefined);
                  form.resetFields();
                }}
                onSelect={handleRegions}
                showSearch={true}
                filterOption={false}
                onSearch={(e) => fetchItems(e)}
              >
                {itemSearch?.map((k, i) => (
                  <Select.Option key={i} value={k.value}>
                    {k.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={"regions"}
              label={"Region"}
              rules={[
                {
                  required: true,
                  message: "Region seçin",
                },
              ]}
            >
              <Select
                allowClearautoSize
                mode={"multiple"}
                placeholder={"Region seçin"}
                showSearch={true}
                filterOption={false}
              >
                {selectedItem &&
                  itemSearch
                    ?.filter((k) => k.value === selectedItem)[0]
                    .availableRegions?.map((t, i) => (
                      <Select.Option value={t.value} key={i}>
                        {t.title || t.label}
                      </Select.Option>
                    ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={"pricing_date"}
              label={"Qiymətləndirmə tarixi"}
              rules={[
                {
                  required: true,
                  message: "Qiymətləndirmə tarixi qeyd edin!",
                },
              ]}
            >
              <DatePicker
                showTime
                style={{ width: "100%" }}
                disabledDate={(current) => {
                  const now = moment();
                  return current > now;
                }}
                placeholder={"Vaxt seçin"}
                format={"YYYY-DD-MM HH:mm"}
              />
            </Form.Item>
          </Form>
          {(() => {
            if (error) {
              return <ErrorHolder error={error} />;
            }
          })()}
        </div>
      </Modal>

      <GenericFormModal
        ignoreRecord={ignoreRecord}
        recordId={editingRecordId}
        update={() => setIsUpdated(!isUpdated)}
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        ignoreRecordId
        dataManipulation={(record) => {
          const formData = new FormData();
          for (const name in record) {
            if (name !== "attachments") formData.append(name, record[name]);
          }

          for (let i = 0; i < fileList.length; i++) {
            formData.append(`attachments[]`, fileList[i]);
          }

          return formData;
        }}
        dataEntrance={(record) => {
          if (record.status !== 0 && record.status !== 2)
            delete record["status"];

          setAttachments(record.attachments);

          setAssignmentType(record.assignment_type);

          record.pricing_date = moment(record.pricing_date);
          return record;
        }}
        mainFunctions={{ get: getOperatorTask, update: putOperatorTask }}
      >
        <Form.Item name={"task_id"} label={"Task kodu"}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          name={"lastPrice"}
          label={
            assignmentType === 4 ? "Maşının əvvəlki qiyməti" : "Əvvəlki qiyməti"
          }
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name={"price"}
          label={assignmentType === 4 ? "Maşının qiyməti" : "Qiymət"}
          rules={[
            {
              required: true,
              message: "Qiymət daxil edin!",
            },
          ]}
        >
          <InputNumber style={{ width: "100%" }} placeholder={"100"} />
        </Form.Item>
        {assignmentType === 4 && (
          <Form.Item
            name={"lastSalaryPrice"}
            label={"Maşinistin əvvəlki əmək haqqı"}
          >
            <InputNumber
              disabled
              style={{ width: "100%" }}
              placeholder={"100"}
            />
          </Form.Item>
        )}
        {assignmentType === 4 && (
          <Form.Item
            name={"salary_price"}
            label={"Maşinistin əmək haqqı"}
            rules={[
              {
                required: true,
                message: "Qiymət daxil edin!",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} placeholder={"100"} />
          </Form.Item>
        )}
        <Form.Item name={"description"} label={"Qeyd"}>
          <TextArea placeholder="Qeyd" autoSize={{ minRows: 3, maxRows: 5 }} />
        </Form.Item>
        <Form.Item name={"pricing_date"} label={"Qiymətləndirmə tarixi"}>
          <DatePicker
            style={{ width: "100%" }}
            disabled
            placeholder={"Vaxt seçin"}
            showTime
            format={"YYYY-DD-MM HH:mm"}
          />
        </Form.Item>
        <Form.Item
          name={"status"}
          label={"Statusu"}
          rules={[
            {
              required: true,
              message: "Status təyin edin!",
            },
          ]}
        >
          <Radio.Group>
            <Radio value={0}>Qaralama</Radio>
            <Radio value={2}>Hazır</Radio>
          </Radio.Group>
        </Form.Item>
        {attachments.length !== 0 && (
          <>
            <Text style={{ fontWeight: "bold" }}>Qoşmalar</Text>
            <div>
              {attachments.map((k) => (
                <div key={k.id}>
                  <a
                    onClick={() => downloadFile(k.id, k.name)}
                    style={{ padding: 0, margin: 0 }}
                  >
                    {k.name} - {k.uploaded_by} tərəfindən yükləndi
                  </a>
                </div>
              ))}
            </div>
          </>
        )}
        <Form.Item
          name="attachments"
          label="Qoşma əlavə edin"
          getValueFromEvent={({ file }) => file.originFileObj}
        >
          <Upload
            maxCount={1}
            fileList={fileList}
            beforeUpload={(file, fileList) => {
              setFileList(fileList);
            }}
            onRemove={(e) => {
              setFileList(fileList.filter((k) => k.uid !== e.uid));
            }}
            multiple
          >
            <Button type={"upload"}>Yüklə</Button>
          </Upload>
        </Form.Item>
      </GenericFormModal>

      <FailedShowModal
        title={
          dropDown.find((x) => x.type === excelRecordId)?.text +
          " - Uğursuz əməliyyatlar"
        }
        setIsModalVisible={setFailedModalVisible}
        isModalVisible={failedModalVisible}
        loading={loading}
      >
        <ul>
          {resultImport !== null &&
            resultImport.failed_rows.map((item) => <li>{item}</li>)}
        </ul>
      </FailedShowModal>

      <ExportExcelModal
        title={
          dropDown.find((x) => x.type === excelRecordId)?.text +
          " - Excel import"
        }
        setIsModalVisible={setIsExcelModalVisible}
        isModalVisible={isExcelModalVisible}
        recordId={excelRecordId}
        loading={loading}
        formIsValid={selectedData != null && resultImport === null}
        onSubmit={onFinishExport}
        onClose={() => {
          setSelectedData(null);
          setResultImport(null);
        }}
      >
        {!selectedData && (
          <Dragger
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            disabled={loading}
            {...propsDragger}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Faylları bura daşıyın və ya yükləmək üçün klikləyin
            </p>
            <p className="ant-upload-hint">
              Faylın həcmi maksimum 50 MB olmalıdır
            </p>
          </Dragger>
        )}

        {selectedData && (
          <p style={{ marginBottom: 3, marginTop: 3 }}>Seçilmiş fayl:</p>
        )}
        {selectedData && selectedData.file && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ color: "#40a9ff" }}>{selectedData.file.name}</p>{" "}
            <Tooltip title="Faylı sil">
              <DeleteOutlined
                style={{ color: "red" }}
                onClick={() => {
                  setSelectedData(null);
                  setResultImport(null);
                }}
              />{" "}
            </Tooltip>{" "}
          </div>
        )}
        {resultImport !== null && (
          <div style={{ margin: 0 }}>
            <p style={{ marginBottom: 0, marginTop: 10 }}>Nəticə:</p>
            <div>
              <CheckCircleOutlined style={{ color: "green" }} /> Uğurlu Nəticə:{" "}
              {resultImport.total_succeeded}
            </div>
            <div>
              <CloseCircleOutlined style={{ color: "red" }} /> Uğursuz Nəticə:{" "}
              {resultImport.total_failed}{" "}
              {resultImport.total_failed > 0 && (
                <InfoCircleOutlined
                  style={{ fontSize: 12, color: "blue", cursor: "pointer" }}
                  onClick={() => {
                    setFailedModalVisible(true);
                  }}
                />
              )}
            </div>
          </div>
        )}
      </ExportExcelModal>

      <ExtendedTable
        history={history}
        fetchFunction={getOperatorTasks}
        update={isUpdated}
        expandable={{
          expandedRowRender: (record) => <ExpandedRow record={record} />,
          expandIcon: ({ expanded, onExpand, record }) => (
            <Button size={"small"} onClick={(e) => onExpand(record, e)}>
              <HistoryOutlined />
            </Button>
          ),
          rowExpandable: (record) => record.progressLogs.length >= 0,
        }}
        actions={{
          custom: [
            {
              icon: <SecurityScanOutlined />,
              onClick: (id) => {
                setEditingRecordId(id);
                setIgnoreRecord(false);
                setIsModalVisible(true);
              },
            },
            {
              icon: <FileSearchOutlined />,
              onClick: (id, record) => {
                const url = new URL(window.location);
                const win = window.open(
                  url.origin + "/dashboard/item/" + record.item_id,
                  "_blank"
                );
                win.focus();
              },
            },
          ],
        }}
      />
    </div>
  );
}
