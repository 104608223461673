import {Button, Col, DatePicker, Dropdown, Form, Menu, Row, Select} from "antd";
import ecoDistricts from "../assets/ecoDistricts";
import ExportButton from "./ExportButton";
import useForm from "antd/es/form/hooks/useForm";
import dayjs from "dayjs";
import moment from "moment";

const {RangePicker} = DatePicker;

export default function ReportFilters({get, noRange = false, dropDown = []}) {

    const download = async (extraParams) => {

        try {
            await form.validateFields()
            try {
                const params = form.getFieldsValue();

                const fil = {regions: params.regions};
                let pag = {}

                if (!noRange) {
                    pag = {
                        yearStart: dayjs(params.range[0]).format("YYYY-MM-DD"),
                        yearEnd: dayjs(params.range[1]).format("YYYY-MM-DD")
                    };
                }

                return get({...fil, ...pag, ...extraParams})
            } catch (e) {

            }
        } catch (e) {
            return null;
        }

    }

    const [form] = useForm();
    return (
        <Form onFinish={download} layout={'vertical'} form={form} name={"item-search"}
              scrollToFirstError
              fields={[{
                  name: ["regions"],
                  value: [0],
              }]}>
            <Row gutter={4} span={24} style={{width: "100%"}}>
                {
                    noRange ? <></>
                        :
                        <Col span={6}>
                            <Form.Item name={"range"}
                                       rules={[
                                           {
                                               required: true,
                                               message: "Məlumat daxil edin!",
                                           }
                                       ]}
                            >
                                <RangePicker format={"YYYY-MM-DD"} placeholder={["Başlanğıc", "Son"]} disabledDate={(current) => {
                                    const now = moment();
                                    return current > now;
                                }} allowClear style={{width: "100%"}}
                                             showTime/>
                            </Form.Item>
                        </Col>
                }
                <Col span={noRange ? 8 : 6}>
                    <Form.Item name={"regions"}
                               rules={[
                                   {
                                       required: true,
                                       message: "Məlumat daxil edin!",
                                   }
                               ]}>
                        <Select mode={"multiple"} allowClear style={{width: "100%"}}
                                placeholder={"Iqtisadi region"}
                                options={ecoDistricts}/>
                    </Form.Item>
                </Col>
                <Col span={noRange ? 8 : 6}>
                    <ExportButton get={download} dropdown={dropDown}/>
                </Col>
                <Col span={noRange ? 8 : 6}>
                    <Button onClick={() => form.resetFields()} style={{width: "100%"}}>
                        Təmizlə
                    </Button>
                </Col>

            </Row>
        </Form>
    )
}