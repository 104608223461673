import {Breadcrumb, Col, Row, Skeleton} from "antd";
import {useEffect} from "react";

export default function PageHeading({title, path, children, docTitleFromPath = false, loading = false}) {

    useEffect(() => {
        document.title = title ? title + " | Smeta Normativ Qiymətqoyma" : "Smeta Normativ Qiymətqoyma";
    }, [loading, title])

    return (
        <div>
            <div style={{marginBottom: "10px", display: "flex", flex: 1, justifyContent: "space-between"}}>

                {loading ? <Skeleton active paragraph={{rows: 1}}/> : <h1>{title}</h1>}

            </div>
            <Row span={24} gutter={10} style={{marginBottom: "10px"}}>
                <Col span={12}>
                    {loading ? <Skeleton active paragraph={{rows: 1}}/> : (
                        <Breadcrumb>
                            {
                                (() => {
                                    return path.split("/").map((el, k) => <Breadcrumb.Item
                                        key={k}>{el}</Breadcrumb.Item>)
                                })()
                            }
                        </Breadcrumb>
                    )}
                </Col>
                <Col span={12}  style={{textAlign: "right"}}>
                    {children}
                </Col>
            </Row>
        </div>
    )
}