import {useHistory, useParams} from "react-router-dom";
import PageHeading from "../../components/PageHeading";
import {getItemsBasedOnModule, downloadReportFromModule} from "../../adapters/api.adapter";
import ExtendedTable from "../../components/ExtendedTable";
import ReportFilters from "../../components/ReportFilters";
import {FileSearchOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";

const ASSIGNMENT_TYPES = [
    {
        "key": 1,
        "value": 1,
        "label": "Tikintidə tətbiq  edilən  materiallar,  məmulatlar, konstruksiyalar, mebel - inventar və avadanlıqlar üzrə"
    },
    {
        "key": 2,
        "value": 2,
        "label": "Tikinti maşın - mexanizmlərin və avtonəqliyyat vasitələrinin istismar xərcləri barədə məlumatlar"
    },
    {
        "key": 3,
        "value": 3,
        "label": "Tikintidə əməyin ödənilməsinə aid məlumatlar"
    },
    {
        "key": 4,
        "value": 4,
        "label": "Quru, dəniz və hava  nəqliyyatı növləri vasitəsilə yükün daşınması"
    }
]


export default function Items() {
    const history = useHistory()
    const {id} = useParams();
    const [catId, setCatId] = useState(id);
    const [isUpdated, setIsUpdated] = useState();

    useEffect(() => {
        setCatId(id);
        setIsUpdated(!isUpdated)
    }, [id])

    return (
        <div>
            <PageHeading
                title={ASSIGNMENT_TYPES[catId - 1].label}
                path={"Panel/Hesabatlar/Toplu Hesabat/" + ASSIGNMENT_TYPES[catId - 1].label}>
                <ReportFilters get={downloadReportFromModule(catId)} noRange/>
            </PageHeading>
            <ExtendedTable
                history={history}
                update={isUpdated}
                actions={{
                    custom: [
                        {
                            icon: <FileSearchOutlined/>,
                            onClick: (id) => {
                                history.push("/dashboard/item/" + id);
                            }
                        }
                    ]
                }}
                fetchFunction={getItemsBasedOnModule(catId)}
            />
        </div>
    )
}