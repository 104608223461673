import axios from "axios";
import appConfig from "./app.config";

const axios_api_instance = axios.create({
  baseURL: appConfig.api.baseURL,
});
axios_api_instance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = localStorage.getItem("asan_login_token");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios_api_instance.interceptors.response.use(
  (response) => response,
  (err) => {
    const url = new URL(window.location);

    if (err.response && err.response.status === 401) {
      localStorage.clear();
      setTimeout(() => {
        localStorage.setItem(
          "lastError",
          JSON.stringify({
            message:
              "Sistemdə belə bir istifadəçi yoxdur və ya asan sessiya müddətiniz bitmişdir.",
          })
        );
        window.location.href = `${url.origin}/error`;
      }, 1000);
      return Promise.reject(err);
    }
    if (err.response && err.response.status === 403) {
      setTimeout(() => {
        localStorage.setItem(
          "lastError",
          JSON.stringify({
            message: "Sizin bu tətbiqdən istifadə etməyə icazəniz yoxdur.",
          })
        );
        window.location.href = `${url.origin}/error`;
      }, 1000); //Prod
      return Promise.reject({
        response: {
          data: {
            message: "Sizin bu tətbiqdən istifadə etməyə icazəniz yoxdur.",
          },
        },
      });
    }
    if (err.response && err.response.status >= 500) {
      localStorage.setItem(
        "lastError",
        JSON.stringify({
          message: "Sistemlə bağlı xəta baş verdi. 500 Server Error",
          errors: [],
        })
      );
      window.location.href = `${url.origin}/error`;
      return Promise.reject({
        response: {
          data: {
            message: "Sistemlə bağlı xəta baş verdi. 500 Server Error",
            errors: [],
          },
        },
      });
    }
    return Promise.reject(err);
  }
);
export default axios_api_instance;
