import Layout from "../../components/Layout";
import UserContextProvider from "../../contexts/user.context";

function Dashboard() {
    return (
        <UserContextProvider>
            <Layout/>
        </UserContextProvider>
    );
}

export default Dashboard;
