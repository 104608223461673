import React, {useEffect, useState} from 'react';
import {Result} from "antd";
import appConfig from "../../config/app.config";

const ErrorPage = () => {

    const [error, setError] = useState();

    useEffect(() => {
        setError(JSON.parse(localStorage.getItem('lastError')));
        setTimeout(() => {
            const url = new URL(window.location);
            window.location.href = window.location.href = `${appConfig.asan_login.baseURL}/auth?origin=${url.origin}`;
            localStorage.clear();
        }, 5000);
        return () => {}
    }, [])

    return <div style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        <Result
            status={'warning'}
            title={error && error.message}
            subTitle={"5 saniyə sonra asan login kabinetinə yönləndiriləcəksiniz"}
        />
    </div>
}

export default ErrorPage;