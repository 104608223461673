import React, {createContext, useEffect, useState} from 'react';
import {getSessionUser} from "../adapters/api.adapter";

export const UserContext = createContext({});

export default function UserContextProvider (props) {
    const [user, setUser] = useState()

    useEffect(() => {
        getSessionUser().then((data) => {
            setUser(data);
        })
    }, [])

    return (
        <UserContext.Provider value={{user}}>
            {props.children}
        </UserContext.Provider>
    )
}