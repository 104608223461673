import {Col, List, Row, Timeline} from "antd";
import {ClockCircleOutlined} from '@ant-design/icons';

export default function ExpandedRow({record}) {
    return (
        <Row span={24}>
            <Col span={3}>
                <Timeline>
                    {
                        record.initialProgress.map(t => {
                            return t.completed ? <Timeline.Item key={t.index}>{t.status}</Timeline.Item> : <Timeline.Item color={"red"} key={t.index} dot={<ClockCircleOutlined />}>{t.status}</Timeline.Item>
                        })
                    }
                </Timeline>
            </Col>
            <Col span={21}>
                <List
                    size="small"
                    style={{width: "100%"}}
                    header={<div>Tarixçə</div>}
                    bordered
                    dataSource={record.progressLogs}
                    renderItem={item => <List.Item>
                        <Row style={{width: "100%"}}>
                            <Col span={4}>
                                {item.createdAt}
                            </Col>
                            <Col span={4}>
                                {item.operatedBy}
                            </Col>
                            <Col span={16}>
                                {item.description}
                            </Col>
                        </Row>
                    </List.Item>}
                />
            </Col>
        </Row>
    )
}