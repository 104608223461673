const distcs = [
    {
        label: "Ümumi",
        key: 0,
        value: 0,
    },
    {
        label: "Bakı",
        key: 1,
        value: 1,
    },
    {
        label: "Naxçıvan",
        key: 2,
        value: 2,
    },
    {
        label: "Abşeron-Xızı",
        key: 3,
        value: 3,
    },
    {
        label: "Dağlıq Şirvan",
        key: 4,
        value: 4,
    },
    {
        label: "Gəncə-Daşkəsən",
        key: 5,
        value: 5,
    },
    {
        label: "Qarabağ",
        key: 6,
        value: 6,
    },
    {
        label: "Qazax - Tovuz",
        key: 7,
        value: 7,
    },
    {
        label: "Quba - Xaçmaz",
        key: 8,
        value: 8,
    },
    {
        label: "Lənkəran - Astara",
        key: 9,
        value: 9,
    },
    {
        label: "Mərkəzi Aran",
        key: 10,
        value: 10,
    },
    {
        label: "Mil - Muğan",
        key: 11,
        value: 11,
    },

    {
        label: "Şəki - Zaqatala",
        key: 12,
        value: 12,
    },
    {
        label: "Şərqi Zəngəzur",
        key: 13,
        value: 13,
    },
    {
        label: "Şirvan - Salyan",
        key: 14,
        value: 14,
    }
]

export default distcs;