import React from "react";

export default function ErrorHolder({error}) {
    return (
        <div>
            <div style={{color: "orangered"}}>
                <p>{error.message}</p>
            </div>
            <div>
                {
                    (() => {
                        const errorTags = Object.entries(error.errors);
                        return errorTags.map((t, k) => {
                            return (
                                <div style={{color: "orangered"}} key={k}>
                                    <div>
                                        {
                                            (() => {
                                                return t[1].map((l, k) => <p key={k}>{l}</p>)
                                            })()
                                        }
                                    </div>
                                </div>
                            )
                        })
                    })()
                }
            </div>
        </div>
    )
}