import {Line} from "@ant-design/charts";
import {useEffect, useState} from "react";
import {Select} from "antd";


export default function Chart({data, type = 0}) {
    const [formattedData, setFormattedData] = useState([]);
    const [selectedChart, setSelectedChart] = useState(0);

    useEffect(() => {

        formattedData[0] =
            data.map((k) => (
                {
                    tarix: k.date,
                    "Son Qiymət": k.currentPrice,
                    "Cari qiymət": k.prevPrice,
                }
            ))


        if (type === 4) {
            formattedData[1] = data.map((k) => (
                {
                    tarix: k.date,
                    "Maşinistin son əmək haqqı": k.salaryPrice,
                    "Maşinistin cari əmək haqqı": k.prevSalary,
                }
            ))
        }

        setFormattedData([...formattedData])



    }, [data])

    const config = {
        data: formattedData[0],
        height: 500,
        xField: 'tarix',
        yField: 'Son Qiymət',
        point: {
            size: 5,
            shape: 'diamond',
        },
        tooltip: {
            showMarkers: false,
            fields: ['Əvvəlki Qiymət', 'Son Qiymət'],
        },
        label: {
            style: {
                fill: '#aaa',
            },
        },
    };

    const config2 = {
        data: type === 4 ? formattedData[1] : [],
        height: 500,
        xField: 'tarix',
        yField: 'Maşinistin son əmək haqqı',
        point: {
            size: 5,
            shape: 'diamond',
        },
        tooltip: {
            showMarkers: false,
            fields: ['Maşinistin cari əmək haqqı', 'Maşinistin son əmək haqqı'],
        },
        label: {
            style: {
                fill: '#aaa',
            },
        },
    };


    return <div style={{backgroundColor: "white", border: "1px solid #ccc", padding: "10px"}}>
        {
            formattedData.length !== 0 ?
                <div>
                    {
                        type === 4 && (
                            <div>
                                <Select defaultValue={selectedChart} onSelect={(k) => setSelectedChart(k)}>
                                    <Select.Option value={0}>Maşının qiymət qrafiki</Select.Option>
                                    <Select.Option value={1}>Maşinistin əmək haqqı qrafiki</Select.Option>
                                </Select>
                            </div>
                        )
                    }
                    <div style={{marginTop: type ===4 ? "12px" : "0px"}}>
                        {
                            selectedChart === 0 ? (
                                <div>
                                    <Line {...config} />
                                </div>
                            ) : (
                                <div>
                                    <Line {...config2} />
                                </div>
                            )
                        }
                    </div>
                </div> : <></>
        }
    </div>
}

