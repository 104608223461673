import React, {useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import appConfig from "../config/app.config";

const ProtectedRoute = ({ component: Component, user, ...rest }) => {

    useEffect(() => {
        const url = new URL(window.location);

        if(!user) return window.location.href = `${appConfig.asan_login.baseURL}/auth?origin=${url.origin}`;
    }, [])

    return (
        <Route {...rest} render={
            props => {
                if (user) {
                    return <Component {...rest} {...props} />
                } else {
                    return <Redirect to={
                        {
                            pathname: '/',
                            state: {
                                from: props.location
                            }
                        }
                    } />
                }
            }
        } />
    )
}

export default ProtectedRoute;