import {Statistic, Card, message, Row, Col} from 'antd';

import {
    AiOutlineCheckCircle,
    FaRegTimesCircle,
    FaShuttleVan,
    FaTruckLoading,
    FaMoneyBillWave,
    GiWoodenCrate,
    CgOrganisation,
    CgFileDocument,
    FaUserAlt, FaUserAltSlash, AiFillAccountBook,
} from 'react-icons/all';

import NoOrg from '../../assets/org-no.svg';

import PageHeading from "../../components/PageHeading";
import {useEffect, useState} from "react";
import {getReports} from "../../adapters/api.adapter";
import {FieldTimeOutlined} from "@ant-design/icons";


// const SYSTEM_OWNER = 4;
// const OBSERVATION_MEMBER = 3;
// const VERIFIER = 2;
// const MONITORING_MEMBER = 1;


export default function Index() {
    const [data, setData] = useState();


    useEffect(() => {
        getReports().then(data => setData(data))
    }, [])


    return (
        <div>
            <PageHeading title={"Ana Səhifə"} path={"Panel/Ana Səhifə"}/>
            <div style={{marginTop: "15px"}}>
                <Row gutter={10}>
                    <Col span={12}>
                        <MiddleHeading>İstifadəçilər haqqında məlumatlar</MiddleHeading>
                    </Col>
                    <Col span={12}>
                        <MiddleHeading>Təşkilatlar haqqında məlumatlar</MiddleHeading>
                    </Col>
                </Row>
            </div>
            <div>
                <Row gutter={10}>
                    <Col span={6}>
                        <Card>
                            <Statistic title="Aktiv istifadəçilər" loading={!data} prefix={<FaUserAlt/>}
                                       value={data?.activeUsers}/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic title="Deaktiv istifadəçilər" loading={!data} prefix={<FaUserAltSlash/>}
                                       value={data?.inactiveUsers}/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic title="Aktiv təşkilatlar" loading={!data} prefix={<CgOrganisation/>}
                                       value={data?.organizations}/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic title="Deaktiv təşkilatlar" loading={!data} prefix={<img style={{width: "30px"}} src={NoOrg}/>}
                                       value={data?.deactive_organizations}/>
                        </Card>
                    </Col>
                </Row>
            </div>
            <div style={{marginTop: "15px"}}>
                <MiddleHeading>Toplular haqqında məlumatlar</MiddleHeading>
                <Row gutter={10}>
                    <Col span={6}>
                        <Card style={{minHeight: "135px"}}>
                            <Statistic title="Tikintidə istifadə edilən materiallar üzrə soraqça sayı" loading={!data} prefix={<GiWoodenCrate/>}
                                       value={data?.products}/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card style={{height: "135px"}}>
                            <Statistic title="Mexanizm istismar xərcləri barədə soraqça sayı" loading={!data} prefix={<FaShuttleVan/>}
                                       value={data?.mechanisms}/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card style={{height: "135px"}}>
                            <Statistic title="Tikintidə əməyin ödənilməsinə aid soraqça sayı" loading={!data} prefix={<FaMoneyBillWave/>}
                                       value={data?.salary_plans}/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card style={{height: "135px"}}>
                            <Statistic title="Quru, dəniz və hava növləri vasitəsi ilə yük daşınma xərcləri barədə soraqça sayı" loading={!data} prefix={<FaTruckLoading/>}
                                       value={data?.load_transportation}/>
                        </Card>
                    </Col>
                </Row>
            </div>

            <GeneralInfo
                title="Tikintidə istifadə edilən materiallar, məmulatlar, konstruksiyalar, mebel - inventar və avadanlıqlar üzrə məlumatların ümumi tarixçəsi"
                loading={!data}
                data1={data?.product_update_tasks}
                data2={data?.totalProductLogsInAudit}
                data3={data?.product_update_approved}
                data4={data?.product_update_rejected} />
            <GeneralInfo
                title="Tikinti maşın - mexanizmlərin və avtonəqliyyat vasitələrinin istismar xərcləri barədə məlumatların ümumi tarixçəsi"
                loading={!data}
                data1={data?.mechanism_update_tasks}
                data2={data?.totalMechanismLogsInAudit}
                data3={data?.mechanism_update_approved}
                data4={data?.mechanism_update_rejected} />
            <GeneralInfo
                title="Tikintidə əməyin ödənilməsinə aid məlumatların ümumi tarixçəsi"
                loading={!data}
                data1={data?.salary_update_tasks}
                data2={data?.totalSalaryPaymentLogsInAudit}
                data3={data?.salary_update_approved}
                data4={data?.salary_update_rejected} />
            <GeneralInfo
                title="Quru, dəniz və hava  nəqliyyatı növləri vasitəsilə yükün daşınması barədə məlumatların ümumi tarixçəsi"
                loading={!data}
                data1={data?.load_transportation_update_tasks}
                data2={data?.totalLoadTransportationLogsInAudit}
                data3={data?.load_transportation_update_approved}
                data4={data?.load_transportation_update_rejected} />

        </div>
    )
}

const MiddleHeading = ({children}) => {
    const divColor = {color: "rgba(0,0,0,0.75)"}

    return <h1 style={divColor}>{children}</h1>
}

const GeneralInfo = ({loading, title, data4, data1, data2, data3}) => {
    return (
        <div style={{marginTop: "15px"}}>
            <MiddleHeading>{title}</MiddleHeading>
            <Row gutter={10}>
                <Col span={6}>
                    <Card>
                        <Statistic title="Ümumi məlumatlar" loading={loading} prefix={<CgFileDocument/>}
                                   value={data1}/>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title="Təsdiqləmə gözləyənlər" loading={loading} prefix={<FieldTimeOutlined />}
                                   value={data2}/>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title="Təsdiqlənmiş" loading={loading} prefix={<AiOutlineCheckCircle/>}
                                   value={data3}/>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title="İmtina olunanlar" loading={loading} prefix={<FaRegTimesCircle/>}
                                   value={data4}/>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}