import { Spin } from "antd";
import { useState } from "react";
import Modal from "antd/es/modal/Modal";

export default function FailedShowModal({
  title,
  isModalVisible,
  setIsModalVisible,
  onClose = () => {},
  formIsValid,
  children,
  loading
}) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      confirmLoading={isLoading}
      title={title}
      visible={isModalVisible}
      cancelText={"Bağla"}
      maskClosable={false}
      keyboard={false}
     footer={null}
      onCancel={(e) => {
        onClose();
        setIsModalVisible(false);
      }}
      width={'550px'}
      bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(90vh - 200px)' }}
    >
      {loading && <Spin tip="Yüklənir..." spinning={loading}>
        {children}
      </Spin> || children}
    </Modal>
  );
}